import { getWidgetEnabled } from "./bh-api-service";
import Cookies from "js-cookie";
import { createBanner, createCartBanner, createWishlistBanner } from "./banner";
import { createSticker } from "./sticker";
import { createStyles, removeStyles } from "./styles";
import {
  createWidget,
  lazyLoadPopupImage,
  loadVideoFromStart
} from "./variants";
import textsDe from "./texts.de.json";
import textsEn from "./texts.en.json";
import textsFr from "./texts.fr.json";
import textsIt from "./texts.it.json";
import { getBase64EncodedRichrelevanceFeed } from "./product";
import {
  createMobileConfiguratorElement,
  isConfigurableProduct
} from "./micasa/mobile-konfigurator";
import {
  createMiocolorProductFinderElement,
  isMiocolorProductFinderPage
} from "./miocolor/product-finder";
import { handleThemeSpecific as handleKlubschuleSpecific } from "./theme/klubschule";
import { handleThemeSpecific as handleBikeworldSpecific } from "./theme/bikeworld";
import {
  isCartPage,
  isProfilePage,
  isProductPage,
  isWishlistPage,
  productIsInCategory,
  productIsInCategories,
  isAppleConfigurator
} from "./utilities-migros";
import { shouldBeHiddenOnPage } from "./utilities";

import { isLiveStreamActive } from "./live-stream";
import { renderSticker, renderBanner, renderWishlistBanner } from "./renderer";

const DEBUG = false;
const POPUP_ENLARGED_COOKIE_NAME = "onlineberatung-enlarged";
const THROTTLED_COOKIE_NAME = "onlineberatung-throttled";
const ENLARGE_POPUP_DELAY_DEFAULT = 30000;
const ENLARGE_POPUP_DELAY_PRODUCT = 3000000;

let from = null;

window.BH_BAMBUSER_CLOSE = false;

window.addEventListener("message", (e) => {
  if (e.data === "BH_BAMBUSER_CLOSED") {
    window.BH_BAMBUSER_CLOSE = true;
    currentUrl = document.location.href.split("#")[0];
    const { theme, lang } = getThemeAndLang(currentUrl);

    handlePageChange({
      url: currentUrl,
      delay,
      theme,
      lang
    });
  }
});

function supportsWebRTC() {
  const isWebRTCSupported =
    navigator.getUserMedia ||
    navigator.webkitGetUserMedia ||
    navigator.mozGetUserMedia ||
    navigator.msGetUserMedia ||
    window.RTCPeerConnection;

  if (window.navigator.userAgent.indexOf("Edge") > -1) {
    return false;
  }

  if (isWebRTCSupported) {
    return true;
  } else {
    return false;
  }
}

export function logEvent(event, options = null, value) {
  const evt = {
    event: "beratungshero",
    beratungsheroEvent: event,
    beratungsheroOption: options ? Object.values(options).join(",") : undefined,
    beratungsheroValue: value || undefined
  };

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(evt);

  if (DEBUG) {
    console.log(event);
  }
}

export function loadTexts(lang) {
  switch (lang) {
    case "fr":
      return textsFr;
    case "it":
      return textsIt;
    case "en":
      return textsEn;
    default:
      return textsDe;
  }
}

export function isSmartphonePage(url) {
  let isOfCategory = false;
  if (isProductPage(url) && window.dataLayer) {
    window.dataLayer.forEach((item) => {
      if (item.ecommerce && item.ecommerce.impressions) {
        const categoryUrl = item.ecommerce.impressions[0].category;
        if (categoryUrl.match("/smartphone")) {
          isOfCategory = true;
        }
      }
    });
  }

  if (isOfCategory) {
    return true;
  }

  return url.match(/\/(smartphone)/);
}

function isBannedPage(url, theme, lang) {
  let banned = false;
  if (isAppleConfigurator(url)) banned = true;
  if (isProductPage(url) && window.dataLayer) {
    window.dataLayer.forEach((item) => {
      if (
        item.ecommerce &&
        item.ecommerce.detail &&
        item.ecommerce.detail.products &&
        item.ecommerce.detail.products[0] &&
        item.ecommerce.detail.products[0].category
      ) {
        const categoryUrl = item.ecommerce.detail.products[0].category;
        if (
          categoryUrl.match("/lust-liebe") ||
          categoryUrl.match("/spielwaren")
        ) {
          banned = true;
        }
      }
    });
  }

  if (isProfilePage(url)) banned = true;

  if (banned) {
    return true;
  }

  if (hasLiveShoppingElement()) {
    return true;
  }

  if (isLiveStreamActive(theme, lang)) {
    return true;
  }

  // check if we are on heimberatung or einrichtungsberatung page and lang is not de to hide widget
  const isUrlEinrichtungOrHeimberatung =
    url.match(/\/(einrichtungsberatung)/) || url.match(/\/(heimberatung)/);
  if (isUrlEinrichtungOrHeimberatung && lang !== "de") {
    return true;
  }

  // Check if we are in an iframe, e.g. when the mini-player is active
  if (!window.BH_BAMBUSER_CLOSE && window.self !== window.top) {
    return true;
  }

  return url.match(
    /\/(cart|checkout|contact|storefinder|lust-liebe|plaisir-et-amour|Singles-Day|singles-day|spielwaren|dailydeal|big-win)/
  );
}

function hasLiveShoppingElement() {
  return !!document.querySelector(".liveShopping");
}

let updateRichrelevanceFeedInterval;
let miocolorWidgetCreated = false;

function handlePageChange({ url, delay, theme, lang }) {
  let root = document.querySelector(".beratungshero--root");
  const hide = isBannedPage(url, theme, lang);

  // if (isLiveStreamActive(theme, lang)) {
  //   initBambuserFloatingButton();
  // }

  if (root) {
    root.style.display = hide ? "none" : "block";
    if (hide) {
      return;
    }
  }

  // Handle change to miocolor theme
  const { theme: newTheme, lang: newLang } = getThemeAndLang(url);
  theme = newTheme;
  lang = newLang;

  if (isThrottled()) {
    return;
  }

  checkIfEnabled(
    function (consultant, booking, bookingLink, landingPageUrl, tenant) {
      const theme = tenant.name;
      // check if changing from booking to normal widget or vice versa
      const bookingWidgetShown = !!document.querySelector(
        ".beratungshero--booking"
      );

      if (
        (consultant && bookingWidgetShown) ||
        (booking && !bookingWidgetShown)
      ) {
        // remove widget, right widget is added below
        if (root) {
          root.remove();
          root = null;
          removeStyles();
          stylesAdded = false;
        }
      }

      if (isBannedPage(url, theme, lang) && root) {
        root.style.display = "none";
        return;
      }

      const widgetPosition = window?.BH?.widgetPosition;

      if (theme === "miocolor") {
        if (!hide) {
          logEvent("MIOCOLOR_WIDGET_SHOWN");
        }

        if (!miocolorWidgetCreated) {
          if (root) {
            root.remove();
            root = null;
            removeStyles();
            stylesAdded = false;
          }

          if (!stylesAdded) {
            createStyles(theme, lang);
            stylesAdded = true;
          }

          const variant = getVariant(theme);

          createWidget({
            consultant,
            theme,
            lang,
            variant,
            hidden: hide,
            booking,
            bookingLink,
            landingPageUrl,
            tenant,
            position: widgetPosition,
            widgetType: window.BH.widgetType,
            popupVideoUrl: window.BH.popupVideoUrl,
            from,
            url
          });
          miocolorWidgetCreated = true;
        } else {
          updatePopupPrimaryButtonShopUrl();
        }
      } else {
        if (miocolorWidgetCreated) {
          if (root) {
            root.remove();
            root = null;
            miocolorWidgetCreated = false;
            removeStyles();
            stylesAdded = false;
          }
        }
        if (!root) {
          if (!hide) {
            logEvent("WIDGET_SHOWN");
          }
          if (booking) {
            if (!hide) {
              logEvent("WIDGET_APPOINTMENT_SHOWN");
            }
          }

          if (!stylesAdded) {
            createStyles(theme, lang);
            stylesAdded = true;
          }

          const variant = getVariant(theme);

          createWidget({
            consultant,
            theme,
            lang,
            variant,
            hidden: hide,
            booking,
            bookingLink,
            landingPageUrl,
            tenant,
            position: widgetPosition,
            widgetType: window.BH.widgetType,
            popupVideoUrl: window.BH.popupVideoUrl,
            from,
            url
          });
        } else {
          updatePopupPrimaryButtonShopUrl();
        }
      }

      switch (theme) {
        case "klubschule":
          handleKlubschuleSpecific({ url, consultant, lang });
          break;
        case "bikeworld":
          handleBikeworldSpecific({ url, consultant, lang });
          break;
      }

      if (updateRichrelevanceFeedInterval) {
        clearInterval(updateRichrelevanceFeedInterval);
      }
      if (!booking && isProductPage(url)) {
        updateRichrelevanceFeedInterval = setInterval(
          updateRichrelevanceFeed,
          5000
        );
      }

      if (theme === "klubschule") {
        enlargePopup({ delay: ENLARGE_POPUP_DELAY_DEFAULT, theme, lang });
        return;
      }

      if (theme === "melectronics" && isSmartphonePage(url)) {
        enlargePopup({ delay: ENLARGE_POPUP_DELAY_DEFAULT, theme, lang });
        return;
      }

      const uri = new URL(url);

      if (theme === "micasa" && uri.href.includes("einrichtungsberatung")) {
        enlargePopup({ delay: 10000, theme, lang });
      }

      if (theme === "micasa" && uri.href.includes("heimberatung")) {
        enlargePopup({ delay: 10000, theme, lang });
      }

      if (isWishlistPage(url) && !booking) {
        createWishlistBanner({ url, consultant, theme, lang });
        return;
      }

      if (isCartPage(url) && !booking) {
        createCartBanner({ url, consultant, theme, lang });
        return;
      }

      if (theme === "miocolor" && isMiocolorProductFinderPage(url)) {
        createMiocolorProductFinderElement({ url, consultant, theme, lang });
        return;
      }

      if (isProductPage(url) && !isBannedPage(url, theme, lang)) {
        // enlarge popup also for booking appointment
        enlargePopup({
          delay,
          theme,
          lang
        });

        if (!booking) {
          logOptimizeEvent("optimize.sticker.show");
          createSticker({
            url,
            consultant,
            theme,
            lang
          });

          createBanner({ url, consultant, theme, lang });

          if (theme === "micasa" && isMobile() && isConfigurableProduct(url)) {
            createMobileConfiguratorElement({ url, consultant, theme, lang });
          }
        }
      }
    },
    theme,
    lang,
    url
  );
}

function enlargePopup({ delay }) {
  if (window.BH.popupVideoUrl !== null) {
    loadVideoFromStart();
  }

  timer = setTimeout(function () {
    const widget = document.querySelector(
      ".beratungshero--root .beratungshero--popup"
    );
    if (widget) {
      // Show popup
      const enlarged = Cookies.get(POPUP_ENLARGED_COOKIE_NAME);
      if (!enlarged && !window.BH.widgetBig) {
        lazyLoadPopupImage();
        widget.style.transform = "scale(1)";
        const avatar = document.querySelector(".beratungshero--avatar");
        avatar
          .querySelector(".beratungshero--avatar-img")
          .classList.remove("beratungshero--avatar-pulse");
        window.BH.widgetClosed = false;
        Cookies.set(POPUP_ENLARGED_COOKIE_NAME, true);
        window.BH.widgetBig = true;
      }
    }
  }, delay);
}

let currentUrl = document.location.href.split("#")[0];
let stylesAdded = false;
if (!window.BH) {
  window.BH = {
    widgetBig: false,
    widgetClosed: true
  };
}

window.BH["renderSticker"] = renderSticker;
window.BH["renderBanner"] = renderBanner;
window.BH["renderWishlistBanner"] = renderWishlistBanner;

let timer;

function getParam(url, name) {
  return new URL(url).searchParams.get(name);
}

function checkIfEnabled(callback, theme, lang, url) {
  const handleWidgetEnabledResponse = (data) => {
    const {
      enabled,
      error,
      booking,
      bookingLink,
      duringOfficeHours,
      consultantAvailable,
      consultant,
      landingPageUrl,
      tenant
    } = data;

    let throttled;
    if (Cookies.get(THROTTLED_COOKIE_NAME) === undefined) {
      throttled =
        Math.floor(Math.random() * 100) > tenant.throttlingRatio * 100;
      Cookies.set(THROTTLED_COOKIE_NAME, throttled);
    } else {
      throttled = isThrottled();
    }

    if (throttled) {
      // don't call callback
      return;
    }

    // todo better solution for landing pages
    let landingPageUrl2;
    if (landingPageUrl) {
      landingPageUrl2 = landingPageUrl.replace(/\/de\//, `/${lang}/`);
    } else {
      landingPageUrl2 = tenant.landingPageUrls[lang];
    }

    if (shouldBeHiddenOnPage(theme)) return;

    if (!error && enabled) {
      if (duringOfficeHours && consultantAvailable) {
        logOptimizeEvent("optimize.widget.show");
        logOptimizeEvent("optimize.widget.call.show");

        if (tenant.name === "sportxx" || tenant.name === "sportx") {
          if (
            url.match(/\/bekleidung-schuhe\/bekleidung/) ||
            url.match(/\/ausruestung\/trekking-zubehoer/) ||
            url.match(/\/ausruestung\/rucksaecke/) ||
            productIsInCategories(url, [
              "bekleidung-schuhe/bekleidung",
              "ausruestung/trekking-zubehoer",
              "ausruestung/rucksaecke"
            ])
          ) {
            console.log("log optimize outdoor");
            logOptimizeEvent("optimize.widget.call.show.outdoor");
          } else if (
            url.match(/\/sports\/fitness/) ||
            url.match(/\/ausruestung\/fitness/) ||
            productIsInCategories(url, [
              "sports/fitness",
              "ausruestung/fitness-zubehoer",
              "ausruestung/fitnessgeraete"
            ])
          ) {
            console.log("log optimize fitness");
            logOptimizeEvent("optimize.widget.call.show.fitness");
          } else if (
            url.match(/\/sports\/running/) ||
            productIsInCategories(url, ["sports/running"])
          ) {
            console.log("log optimize running");
            logOptimizeEvent("optimize.widget.call.show.running");
          } else if (
            url.match(/\/sports\/ski/) ||
            url.match(/\/ausruestung\/ski/) ||
            url.match(/\/ausruestung\/snow/) ||
            productIsInCategories(url, [
              "ausruestung/langlaufschuhe",
              "ausruestung/langlaufski",
              "ausruestung/schlitten-bobs",
              "ausruestung/schlittschuhe",
              "ausruestung/schneeschuhe",
              "ausruestung/ski",
              "ausruestung/skischuhe",
              "ausruestung/snowboard",
              "ausruestung/snowboard-bindungen",
              "ausruestung/snowboardschuhe",
              "ausruestung/stoecke",
              "ausruestung/tourenzubehoer"
            ])
          ) {
            console.log("log optimize ski");
            logOptimizeEvent("optimize.widget.call.show.ski");
          } else {
            logOptimizeEvent("optimize.widget.call.show.neutral");
          }
        }

        callback(consultant, false, bookingLink, landingPageUrl2, tenant);
      } else {
        if (booking && lang !== "it") {
          logOptimizeEvent("optimize.widget.show");
          callback(null, true, bookingLink, landingPageUrl2, tenant);
        } else {
          if (tenant.fallbackTenantName) {
            from = tenant.name;
            getWidgetEnabled(tenant.fallbackTenantName, lang).then((res) => {
              res.json().then(handleWidgetEnabledResponse);
            });
          } else {
            // remove widget
            const root = document.querySelector(".beratungshero--root");
            if (root) {
              root.remove();
            }
          }
        }
      }
    }
  };

  getWidgetEnabled(theme, lang).then((res) => {
    res.json().then(handleWidgetEnabledResponse);
  });
}

function getThemeAndLang(url) {
  if (url.match(/klubschule.ch/)) {
    return { theme: "klubschule", lang: "de" };
  } else if (url.match(/ecole-club.ch/)) {
    return { theme: "klubschule", lang: "fr" };
  } else if (url.match(/scuola-club.ch/)) {
    return { theme: "klubschule", lang: "it" };
  }

  const match = url.match(
    /(bikeworld|doitgarden|micasa|melectronics|sportx).ch\/(de|fr|it)/
  );

  if (match) {
    const [, theme, lang] = match;

    if (showMiocolorWidget(url, theme, lang)) {
      return {
        theme: "miocolor",
        lang: lang
      };
    }

    // navigator.language references Browser UI language instead of
    // Accept-Language header in Chrome & Edge Chromium
    // https://caniuse.com/mdn-api_navigatorlanguage_language
    if ((navigator.language || "").startsWith("en")) {
      logOptimizeEvent("optimize.browser.en");
      if (
        !!window.BH.englishJourney // is set by Optimize
      ) {
        return {
          theme,
          lang: "en"
        };
      }
    }

    return {
      theme,
      lang
    };
  }

  return {
    theme: null,
    lang: null
  };
}

let delay = window.BH.enlargePopupDelayProduct || ENLARGE_POPUP_DELAY_PRODUCT;

function checkIfShown() {
  let url = currentUrl;
  let delay = window.BH.enlargePopupDelayProduct || ENLARGE_POPUP_DELAY_PRODUCT;

  if (url.match("localhost") || url.match("ngrok")) {
    url =
      "https://www.sportx.ch/de/f/aktionensd?q=%3Arelevance%3Aspecial%3AAktion";
    // "https://www.melectronics.ch/de/p/794687500000/samsung-galaxy-a13-128gb-black";
    //"https://www.bikeworld.ch/de?gclid=CjwKCAjw4ayUBhA4EiwATWyBrpbiEp7R2TQpC2_tC2xH2j3voqDHIMp2aPhKmd58WipqFnDylBjFzhoCPPcQAvD_BwE&gclsrc=aw.ds";
    delay = 1000;
    if (!window.dataLayer) {
      window.dataLayer = [];
    }
    window.dataLayer.push({
      event: "productImpression",
      ecommerce: {
        currencyCode: "CHF",
        recommTool: "custom",
        productCount: 65,
        impressions: [
          {
            uniqueProductId: "794687500000",
            price: "189",
            category: "melectronics/telefonie-navigation/smartphone",
            variant: "standard",
            discountType: true,
            position: 1,
            productURL:
              "https://www.melectronics.ch/de/p/794687500000/samsung-galaxy-a13-128gb-black",
            productImageURL:
              "https://image.migros.ch/original/eba29be15be85e1e38e404909b709dde72c3e4d2/samsung-galaxy-a13-128gb-black-smartphone.jpg",
            name: "Galaxy A13 128GB Black",
            id: "794687500000",
            brand: "Samsung",
            bossNumber: "240736283005",
            oldPrice: "209",
            productType: "Smartphone",
            list: "kategorieseite",
            listinfo: "3421317829 samsung"
          },
          {
            uniqueProductId: "794687400000",
            price: "369",
            category: "melectronics/telefonie-navigation/smartphone",
            variant: "standard",
            discountType: false,
            position: 2,
            productURL:
              "https://www.melectronics.ch/de/p/794687400000/samsung-galaxy-a33-5g-128gb-awesome-black",
            productImageURL:
              "https://image.migros.ch/original/666ad40dc9535b2a90e6beff80ffc241da69dd88/samsung-galaxy-a33-5g-128gb-awesome-black-smartphone.jpg",
            name: "Galaxy A33 5G 128GB Awesome Black",
            id: "794687400000",
            brand: "Samsung",
            bossNumber: "240736283005",
            productType: "Smartphone",
            list: "kategorieseite",
            listinfo: "3421317829 samsung"
          },
          {
            uniqueProductId: "794687300000",
            price: "449",
            category: "melectronics/telefonie-navigation/smartphone",
            variant: "standard",
            discountType: false,
            position: 3,
            productURL:
              "https://www.melectronics.ch/de/p/794687300000/samsung-galaxy-a53-5g-128gb-awesome-black",
            productImageURL:
              "https://image.migros.ch/original/cc92b9347c2fc652dd7c065cde2435e941bc3c7f/samsung-galaxy-a53-5g-128gb-awesome-black-smartphone.jpg",
            name: "Galaxy A53 5G 128GB Awesome Black",
            id: "794687300000",
            brand: "Samsung",
            bossNumber: "240736283005",
            productType: "Smartphone",
            list: "kategorieseite",
            listinfo: "3421317829 samsung"
          },
          {
            uniqueProductId: "794675000000",
            price: "549",
            category: "melectronics/telefonie-navigation/smartphone",
            variant: "standard",
            discountType: false,
            position: 4,
            productURL:
              "https://www.melectronics.ch/de/p/794675000000/samsung-galaxy-s21-fe-5g-128gb-graphite",
            productImageURL:
              "https://image.migros.ch/original/2534d1f3f83af8b957efa673b450363cc35480ff/samsung-galaxy-s21-fe-5g-128gb-graphite-smartphone.jpg",
            name: "Galaxy S21 FE 5G 128GB Graphite",
            id: "794675000000",
            brand: "Samsung",
            bossNumber: "240736283005",
            productType: "Smartphone",
            list: "kategorieseite",
            listinfo: "3421317829 samsung"
          },
          {
            uniqueProductId: "794683600000",
            price: "749",
            category: "melectronics/telefonie-navigation/smartphone",
            variant: "standard",
            discountType: false,
            position: 5,
            productURL:
              "https://www.melectronics.ch/de/p/794683600000/samsung-galaxy-s22-128gb-phantom-black",
            productImageURL:
              "https://image.migros.ch/original/32d222db7eb1f6fe84eb37542625859890458846/samsung-galaxy-s22-128gb-phantom-black-smartphone.jpg",
            name: "Galaxy S22 128GB Phantom Black",
            id: "794683600000",
            brand: "Samsung",
            bossNumber: "240736283005",
            productType: "Smartphone",
            list: "kategorieseite",
            listinfo: "3421317829 samsung"
          }
        ]
      },
      "gtm.uniqueEventId": 25
    });
  }

  const isWebRTCSupported = supportsWebRTC();
  if (!isWebRTCSupported) {
    return;
  }

  // don't show widget for consultants
  if (getParam(url, "hidewidget") === "true") {
    return;
  }

  const utm_campaign = getParam(url, "utm_campaign");

  // Bei Aufforderung für Bewertung nach Kauf, Widget nicht anzeigen
  if (utm_campaign && utm_campaign.toLowerCase().startsWith("kun-01")) {
    return;
  }

  const { theme, lang } = getThemeAndLang(url);
  console.log("theme", theme, "lang", lang);

  if (!theme) {
    return;
  }

  // logEvent(variant === 1 ? "POPUP_VARIANT_A" : "POPUP_VARIANT_B");

  handlePageChange({ url, delay, theme, lang });

  setInterval(function () {
    if (document.location.href.split("#")[0] !== currentUrl) {
      currentUrl = document.location.href.split("#")[0];

      // clear previous trigger to enlarge popup
      clearTimeout(timer);
      handlePageChange({
        url: currentUrl,
        delay,
        theme,
        lang
      });
    }
  }, 1000);
}

checkIfShown();

/*
 * Returns 1 or 2
 * 1: Popup Variante A "grosses Pop Up"
 * 2: Popup Variante B "kleines Pop Up"
 */
function getVariant(theme) {
  if (!isMobile() || theme === "melectronics") {
    return 1;
  } else {
    return 2;
  }
}

function isMobile() {
  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );
  return vw <= 640;
}

function isThrottled() {
  return Cookies.get(THROTTLED_COOKIE_NAME) === "true";
}

function updatePopupPrimaryButtonShopUrl() {
  const popupPrimaryButton = document.querySelector(
    ".js-beratungshero-popup-primary-button"
  );

  if (popupPrimaryButton) {
    const currentLink = popupPrimaryButton.getAttribute("href");
    const newLink = currentLink.replace(
      /&url=.*&consultantId=/,
      `&url=${getShopUrl()}&consultantId=`
    );
    popupPrimaryButton.setAttribute(
      "href",
      newLink.replace(/&richrelevanceFeed=.*/, "")
    );
  }
}

function updateRichrelevanceFeed() {
  const richrelevanceFeed = getBase64EncodedRichrelevanceFeed();

  if (richrelevanceFeed) {
    const popupPrimaryButton = document.querySelector(
      ".js-beratungshero-popup-primary-button"
    );

    if (popupPrimaryButton) {
      const currentLink = popupPrimaryButton.getAttribute("href");
      let newLink;
      if (currentLink.includes("&richrelevanceFeed=")) {
        newLink = currentLink.replace(
          /&richrelevanceFeed=.*/,
          `&richrelevanceFeed=${richrelevanceFeed}`
        );
      } else {
        newLink = `${currentLink}&richrelevanceFeed=${richrelevanceFeed}`;
      }
      popupPrimaryButton.setAttribute("href", newLink);
    }
  }
}

function getShopUrl() {
  let shopUrl = document.location.href.split("#")[0];
  if (shopUrl.match("localhost") || shopUrl.match("ngrok")) {
    shopUrl =
      "https://www.klubschule.ch/Services/Beratung-und-Kontakt/Live-Videoberatung"; //https://qual-www.sportx.ch/de/p/405750100000/diener";
  }
  return shopUrl;
}

function logOptimizeEvent(eventName) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ event: eventName });
}

function showMiocolorWidget(url, theme, lang) {
  return (
    theme === "doitgarden" &&
    (url.match(/\/(miocolor)/) ||
      url.match(/\/(elle-decoration)/) ||
      url.match(/\/(malen)/) ||
      url.match(/\/(schoener-wohnen)/) ||
      url.match(/\/(dupli-color)/) ||
      url.match(/\/(d-c-fix)/) ||
      url.match(/\/(color-expert)/) ||
      url.match(/\/(produktfinder-malen)/) ||
      url.match(/\/(farbmisch-service)/) ||
      url.match(/q=farbe/) ||
      url.match(/q=wand%20farben/) ||
      url.match(/malen/) ||
      url.match(/q=lack/) ||
      productIsInCategory(url, "malen")) &&
    lang === "de"
  );
}

function showSportxTouchpoints(theme, lang) {
  if (
    !document.querySelector(
      ".js__beratungshero--sticker.beratungshero--sticker"
    )
  ) {
    const containerSticker = document.querySelector("#migros-beratungshero");
    if (containerSticker) {
      containerSticker.style.position = "relative";
      window.BH.renderSticker(containerSticker, { tenant: theme, lang });
    }
  }

  if (
    !document.querySelector(
      ".beratungshero--root.p-product-detail--item.p-product-detail--item__details.detail-navigation-section"
    )
  ) {
    const containerBanner = document.querySelector(
      "#accordion-wrapper>div:nth-child(5)"
    );
    if (containerBanner)
      window.BH.renderBanner(containerBanner, { tenant: theme, lang });
  }
}

(() => {
  const match = document.location.href
    .split("#")[0]
    .match(/(bikeworld|doitgarden|micasa|melectronics|sportx).ch\/(de|fr|it)/);

  if (!match) return;

  const [, theme, lang] = match;
  if (theme === "sportx") {
    let previousUrl = "";

    const observer = new MutationObserver(function (mutations) {
      if (location.href !== previousUrl) {
        previousUrl = location.href;

        showSportxTouchpoints(theme, lang);
      }
    });

    const config = { subtree: true, childList: true };
    observer.observe(document, config);
  }
})();
