const TENANTS = [
  "bikeworld",
  "doitgarden",
  "micasa",
  "melectronics",
  "sportxx",
  "sportx"
];

export { TENANTS };
