import { loadTexts, logEvent } from "../widget";
import { getElement } from "../utilities";
import { isProductPage, productIsInCategory } from "../utilities-migros";

const THEME = "bikeworld";
const SIZE_TABLE_ELEMENT_CLASS =
  "beratungshero-button beratungshero-button--secondary-inverted beratungshero-button--size-table";

export function handleThemeSpecific({ url, consultant, lang }) {
  if (productIsInCategory(url, "bikes")) {
    // createSizeTableElement({ url, consultant, theme: THEME, lang }); // currently deactivated
  }
}

async function createSizeTableElement({ url, consultant, theme, lang }) {
  const texts = loadTexts(lang);
  const a = document.createElement("a");
  a.id = "beratungshero-size-table-button";
  a.className = SIZE_TABLE_ELEMENT_CLASS;
  a.style.marginTop = "-9px";
  a.style.marginBottom = "16px";
  a.target = "_blank";
  a.rel = "noopener noreferrer";
  a.href = `${window.BH_URL}/${theme}/${lang}?url=${encodeURIComponent(
    url
  )}&consultantId=${consultant._id}&connect=auto&from=size-table`;
  a.innerHTML = `
  <span>${texts.themeSpecific[THEME].sizeTableButton}</span>
  `;

  const optionBoxElement = await getElement("#detail_variant_option_box");

  if (optionBoxElement) {
    const anchorElement = optionBoxElement.nextSibling;
    if (anchorElement) {
      insertElementAfter(anchorElement, a);
    }
  }
}

function insertElementAfter(anchorElement, elementToInsert) {
  anchorElement.parentNode.insertBefore(
    elementToInsert,
    anchorElement.nextSibling
  );
  logEvent("SIZE_TABLE_SHOWN");

  const element = document.querySelector("#beratungshero-size-table-button");
  if (element) {
    element.onclick = function () {
      logEvent("BUTTON_SIZE_TABLE_CLICKED");
    };
  }
}
