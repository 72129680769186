import { loadTexts } from "../widget";
import { getElement } from "../utilities";
import { attachBannerClickEvent, createOldPdpBannerDiv } from "../banner";

const THEME = "klubschule";

export function handleThemeSpecific({ url, consultant, lang }) {
  const texts = loadTexts(lang);
  const div = createOldPdpBannerDiv({
    url,
    consultant,
    theme: THEME,
    lang,
    text: texts.bannerTitle,
    buttonText: `<span>${texts.bannerButton}</span>`
  });

  getElement(".ksm-course-static-button").then((primaryButton) => {
    if (primaryButton) {
      // insert after primaryButton
      primaryButton.parentNode.insertBefore(div, primaryButton.nextSibling);

      attachBannerClickEvent("BUTTON_BANNER_CLICKED");
    }
  });
}
