/**
 * Cached data from the last widget data fetch. It contains the widget data and the timestamp of the fetch.
 * @type {Object}
 * @property {Object} data - The widget data.
 * @property {string} lang - The language of the widget data.
 * @property {number} timestamp - The timestamp of the fetch.
 */
let cachedData;

/**
 * Loads the widget data from the Beratungshero API.
 * @param {string} lang - The language of the widget data.
 * @param {string} tenant - The tenant of the widget data.
 * @returns {Promise<Object>} - The widget data.
 * @example
 * const widgetData = await loadWidgetData("de", "bikeworld");
 */
export default async function loadWidgetData(lang, tenant) {
  if (!("fetch" in window)) {
    return;
  }

  // Check if we have cached data and that the cached data is not to old and of the same language. If the check is true we return the cached data.
  if (
    cachedData &&
    cachedData.lang === lang &&
    cachedData.timestamp + 1000 * 60 * 2 > Date.now()
  ) {
    return cachedData.data;
  }

  try {
    const data = await (
      await fetch(
        `${window.BH_URL}/api/widget-enabled?rootTheme=${tenant}&theme=${tenant}&lang=${lang}`
      )
    ).json();

    cachedData = {
      data,
      lang,
      timestamp: Date.now()
    };

    return cachedData.data;
  } catch (error) {
    console.error(error);
  }
}
