import { logEvent } from "./widget";

const liveStreamTimes = [
  { from: "2022-08-22T20:00:00+02:00", to: "2022-08-22T21:15:00+02:00" },
  { from: "2022-08-29T20:00:00+02:00", to: "2022-08-29T21:30:00+02:00" }
];

// window.onBambuserLiveShoppingReady = (player) => {
//   player.configure({
//     buttons: {
//       dismiss: player.BUTTON.MINIMIZE,
//       product: player.BUTTON.MINIMIZE
//     }
//   });

//   player.on(player.EVENT.READY, () => {
//     console.log("player is ready");
//     window.BH_BAMBUSER_CLOSE = false;
//   });

//   player.on(player.EVENT.CLOSE, () => {
//     console.log(player);
//     console.log("Player was closed");

//     const iframeDiv = Array.from(document.body.children).find((x) =>
//       x.id?.startsWith("livecommerce-surf-")
//     );

//     const iframe = iframeDiv?.querySelector("iframe");
//     if (iframe) {
//       iframe.contentWindow.postMessage("BH_BAMBUSER_CLOSED");
//     }
//   });
// };

export function isLiveStreamActive(tenant, lang) {
  if (tenant !== "sportxx" || lang !== "de") {
    return;
  }

  const url = new URL(document.location.href);

  if (url.searchParams.get("liveStream")) {
    return true;
  }

  if (url.pathname.startsWith("/de")) {
    const now = new Date();

    return liveStreamTimes.find(
      (x) => new Date(x.from) < now && now < new Date(x.to)
    );
  }
}

// export function initBambuserFloatingButton() {
//   (function (d, t, i, w) {
//     window.__bfwId = w;
//     if (d.getElementById(i) && window.__bfwInit) return window.__bfwInit();
//     if (d.getElementById(i)) return;
//     var s,
//       ss = d.getElementsByTagName(t)[0];
//     s = d.createElement(t);
//     s.id = i;
//     s.src = "https://lcx-widgets.bambuser.com/embed.js";
//     ss.parentNode.insertBefore(s, ss);
//   })(
//     document,
//     "script",
//     "bambuser-liveshopping-widget",
//     "qmlli8i3WMwTCTzBncpG"
//   );
// }

export function initBambuserOverlay(showId) {
  (function () {
    if (!window.initBambuserLiveShopping) {
      window.initBambuserLiveShopping = function (item) {
        window.initBambuserLiveShopping.queue.push(item);
      };
      window.initBambuserLiveShopping.queue = [];
      var scriptNode = document.createElement("script");
      scriptNode["src"] =
        "https://lcx-embed.bambuser.com/powered-by-bambuser-theme/embed.js";
      document.body.appendChild(scriptNode);
    }

    window.initBambuserLiveShopping({
      showId,
      type: "overlay"
    });
  })();
}

document.body.addEventListener("click", (e) => {
  if (e.target.tagName === "A") {
    const hrefParts = e.target.hash.split("_");
    if (hrefParts[0] === "#vbLiveStream") {
      const id = hrefParts[1];
      console.log("open live stream with id", id);
      initBambuserOverlay(id);
    }
  }
});

// const url = new URL(document.location.href);

// if (url.searchParams.get("vbLiveStream") === "play") {
//   const liveStreamLinks = document.querySelectorAll(
//     "a[href^='#vbLiveStream_']"
//   );

//   if (liveStreamLinks.length > 0) {
//     const hrefParts = liveStreamLinks[0].hash.split("_");
//     const id = hrefParts[1];
//     console.log("open live stream with id", id);
//     initBambuserOverlay(id);
//   }
// }

let lastTrackingPoint;
let interval;
let currentShow;

function startTrackViewTime(showId) {
  if (currentShow) {
    if (currentShow !== showId) {
      stopTrackViewTime(currentShow);
    } else {
      return;
    }
  }

  if (interval) {
    clearInterval(interval);
  }

  lastTrackingPoint = new Date().getTime();
  currentShow = showId;

  interval = setInterval(() => {
    trackCurrentProgress(showId);
  }, 15 * 1000);
}

function stopTrackViewTime(showId) {
  if (showId !== currentShow) {
    return;
  }

  trackCurrentProgress(showId);
  clearInterval(interval);
  currentShow = undefined;
  lastTrackingPoint = undefined;
}

function trackCurrentProgress(showId, sendEvent = true) {
  if (showId !== currentShow) {
    return;
  }

  const trackingPoint = new Date().getTime();

  const currentWatchTime =
    parseFloat(window.sessionStorage.getItem("watchTime#" + showId)) || 0;
  const newWatchTime =
    currentWatchTime + (trackingPoint - lastTrackingPoint) / 1000;

  lastTrackingPoint = trackingPoint;

  window.sessionStorage.setItem("watchTime#" + showId, newWatchTime);

  if (sendEvent) {
    liveStreamEvent({
      eventName: "WATCH_TIME",
      showId,
      value: Math.floor(newWatchTime)
    });
  }
}

function getCurrentWatchTime(showId) {
  if (showId === currentShow) {
    trackCurrentProgress(showId, false);
  }
  return parseInt(window.sessionStorage.getItem("watchTime#" + showId)) || 0;
}

// window.addEventListener("bambuser-liveshop-tracking-point", ({ detail }) => {
//   if (detail.event === "on-play") {
//     startTrackViewTime(detail.data.showId);
//   }

//   if (detail.event === "on-stop") {
//     stopTrackViewTime(detail.data.showId);
//   }

//   if (detail.event === "on-interaction") {
//     if (detail.data.interactionType === "pause") {
//       stopTrackViewTime(detail.data.showId);
//     } else if (detail.data.interactionType === "resume") {
//       startTrackViewTime(detail.data.showId);
//     } else if (detail.data.interactionType === "close") {
//       stopTrackViewTime(detail.data.showId);
//     }
//   }

//   let eventName = toEventName(detail.event).replace(/^ON_/, "");

//   switch (eventName) {
//     case "INTERACTION":
//       eventName = toEventName(detail.data.interactionType);
//   }
//   const watchTime = getCurrentWatchTime(detail.data.showId);
//   liveStreamEvent({
//     eventName,
//     showId: detail.data.showId,
//     value: watchTime ? watchTime : undefined
//   });
// });

// window.addEventListener("message", (e) => {
//   if (e.data) {
//     try {
//       const data = JSON.parse(e.data);
//       if (data.eventName === "liveshopping-widget:tracking") {
//         let eventName = toEventName(data.data.eventType).replace(/^ON_/, "");

//         switch (eventName) {
//           case "INTERACTION":
//             eventName = toEventName(data.data.interactionType);
//         }
//         liveStreamEvent({
//           eventName: "WIDGET_" + eventName,
//           showId: data.data.showId
//         });
//       }
//     } catch (e) {
//       // Ignore if json cannot be parsed
//     }
//   }
// });

function liveStreamEvent({ eventName, showId, value }) {
  logEvent("LIVESTREAM_" + eventName, { showId }, value);
}

function toEventName(value) {
  return value.replace(/[:-]/g, "_").toUpperCase();
}
