import { productsAreInDatalayer } from "./utilities-migros";

export function wait(ms) {
  return new Promise((resolve, reject) => {
    setTimeout(() => resolve(), ms);
  });
}

export async function getElement(selector, tries = 0, ms = 200) {
  if (tries > 20) {
    return null;
  }

  const el = document.querySelector(selector);

  if (el) {
    return el;
  }

  await wait(ms);

  return getElement(selector, tries + 1, ms);
}

export async function getElements(selector, tries = 0, ms = 200) {
  if (tries > 10) {
    return null;
  }

  const elements = document.querySelectorAll(selector);

  if (elements && elements.length > 0) {
    return elements;
  }

  await wait(ms);

  return getElements(selector, tries + 1, ms);
}

export function sendHotjarEvent(eventName) {
  if (window.hj !== undefined) {
    window.hj("event", eventName);
  }
}

export function urlHasParameterWithValue(url, ...parameter) {
  const urlParams = new URLSearchParams(url.split("?")[1] || "");
  for (const param of parameter) {
    if (urlParams.has(param[0])) {
      if (
        new URLSearchParams(param.join("=")).get(param[0]) ===
        urlParams.get(param[0])
      )
        return true;
    }
  }
  return false;
}

export function urlMatchesRegex(url, ...paths) {
  for (const path of paths) {
    if (path.test(url)) return true;
  }

  return false;
}

export function shouldBeHiddenOnPage(theme) {
  const url = document.location.href.split("#")[0];

  if (theme === "micasa") {
    const parameterAndValuePairs = [
      ["q", "aktion"],
      ["q", "action"],
      ["q", "azione"],
      ["q", "sale"]
    ];

    if (urlHasParameterWithValue(url, ...parameterAndValuePairs)) {
      return true;
    }

    const urlRegex = [
      /.+\/de\/f\/aktionen[\/|\?]?.*$/i,
      /.+\/fr\/f\/actions[\/|\?]?.*$/i,
      /.+\/it\/f\/azioni[\/|\?]?.*$/i,
      /.+\/de\/cp\/sale[\/|\?]?.*$/i,
      /.+\/fr\/cp\/sale[\/|\?]?.*$/i,
      /.+\/it\/cp\/sale[\/|\?]?.*$/i
    ];

    if (urlMatchesRegex(url, ...urlRegex)) {
      return true;
    }
  } else if (theme === "sportxx" || theme === "sportx") {
    const parameterAndValuePairs = [
      ["q", "sale"],
      ["q", "%3Arelevance%3Aspecial%3AAktion"],
      ["q", "%3Arelevance%3Aspecial%3AActions"],
      ["q", "%3Arelevance%3Aspecial%3AAzioni"]
    ];

    if (urlHasParameterWithValue(url, ...parameterAndValuePairs)) {
      return true;
    }

    const urlRegex = [
      /.+\/de\/f\/aktionen[\/|\?]?.*$/i,
      /.+\/fr\/f\/actions[\/|\?]?.*$/i,
      /.+\/it\/f\/azioni[\/|\?]?.*$/i
    ];

    if (urlMatchesRegex(url, ...urlRegex)) {
      return true;
    }
  } else if (theme === "melectronics") {
    const parameterAndValuePairs = [
      ["q", "salex"],
      ["q", "%3Arelevance%3Aspecial%3AAktion"],
      ["q", "%3Arelevance%3Aspecial%3AActions"],
      ["q", "%3Arelevance%3Aspecial%3AAzioni"]
    ];

    if (urlHasParameterWithValue(url, ...parameterAndValuePairs)) {
      return true;
    }

    const urlRegex = [
      /.+\/de\/cp\/ausverkauf[\/|\?]?.*$/i,
      /.+\/fr\/cp\/ausverkauf[\/|\?]?.*$/i,
      /.+\/it\/cp\/ausverkauf[\/|\?]?.*$/i,
      /.+\/de\/c\/\d+\/tv-audio\/fernseher[\/|\?]?.*$/i,
      /.+\/fr\/c\/\d+\/tv-audio\/televiseurs[\/|\?]?.*$/i,
      /.+\/it\/c\/\d+\/tv-audio\/televisori[\/|\?]?.*$/i,
      /.+\/de\/c\/\d+\/computing-tablets\/notebooks[\/|\?]?.*$/i,
      /.+\/fr\/c\/\d+\/computing-tablettes\/pc-portable[\/|\?]?.*$/i,
      /.+\/it\/c\/\d+\/computing-tablets\/pc-portatile[\/|\?]?.*$/i
    ];

    if (urlMatchesRegex(url, ...urlRegex)) {
      return true;
    }

    if (productsAreInDatalayer(["Notebooks", "Fernseher"])) {
      return true;
    }
  } else if (theme === "doitgarden") {
    const parameterAndValuePairs = [
      ["q", "%3Arelevance%3Aspecial%3AAktion"],
      ["q", "%3Arelevance%3Aspecial%3AActions"],
      ["q", "%3Arelevance%3Aspecial%3AAzioni"]
    ];

    if (urlHasParameterWithValue(url, ...parameterAndValuePairs)) {
      return true;
    }

    const urlRegex = [
      /.+\/de\/f\/aktionen[\/|\?]?.*$/i,
      /.+\/fr\/f\/actions[\/|\?]?.*$/i,
      /.+\/it\/f\/azioni[\/|\?]?.*$/i,
      /.+\/de\/cp\/salecoupon[\/|\?]?.*$/i,
      /.+\/fr\/cp\/salecoupon[\/|\?]?.*$/i,
      /.+\/it\/cp\/salecoupon[\/|\?]?.*$/i,
      /.+\/de\/outlet[\/|\?]?.*$/i,
      /.+\/fr\/outlet[\/|\?]?.*$/i,
      /.+\/it\/outlet[\/|\?]?.*$/i
    ];

    if (urlMatchesRegex(url, ...urlRegex)) {
      return true;
    }
  }

  return false;
}
