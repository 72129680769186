export function isHomePage(url, lang) {
  let regex = new RegExp(`\/${lang}$`);

  return url.match(regex);
}

export function isWishlistPage(url) {
  return url.match(/\/(wishlist)/);
}

export function isCartPage(url) {
  return url.match(/\/(cart)/);
}

export function isCategoryPage(url) {
  return url.match(/\/c\//);
}

export function isContentPage(url) {
  return url.match(/\/cp\//);
}

export function isProductPage(url) {
  return url.match(/\/p\//);
}

export function isProfilePage(url) {
  return url.match(/\/account\//);
}

export function isAppleConfigurator(url) {
  return (
    url.match(/\/cp\/apple-mac-configurator/) ||
    url.match(/\/cp\/produktfinder-cto/)
  );
}

export function productIsInCategory(url, category) {
  if (isProductPage(url) && window.dataLayer) {
    const dataLayer = [...window.dataLayer].reverse();
    for (const item of dataLayer) {
      if (
        item.ecommerce &&
        item.ecommerce.detail &&
        item.ecommerce.detail.products &&
        item.ecommerce.detail.products[0] &&
        item.ecommerce.detail.products[0].category
      ) {
        const categoryUrl = item.ecommerce.detail.products[0].category;
        return categoryUrl.match(`/${category}`);
      }
    }
  }
  return false;
}

export function productsAreInDatalayer(productArray) {
  try {
    if (!window.dataLayer) return null; // if no dataLayer array is found
    // Get a reversed copy of the dataLayer array
    const dataLayer = [...window.dataLayer].reverse();
    // Find the element that contains the pgc attribute
    const foundElement = dataLayer.find((item) => item.hasOwnProperty("pgc"));
    if (!foundElement) return null; // if no element with pgc attribute is found
    // Check if any value in the "pgc" array matches any element in the provided array
    const isMatch = foundElement.pgc.some((value) =>
      productArray.includes(value)
    );
    if (isMatch) {
      return foundElement; // returns the matched element if a match is found
    } else {
      return null; // returns null if no match in the "pgc" array
    }
  } catch (e) {
    console.error(e);
    return null;
  }
}

export function productIsInCategories(url, categories) {
  for (const category of categories) {
    if (productIsInCategory(url, category)) {
      return true;
    }
  }
  return false;
}
