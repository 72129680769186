export function getBase64EncodedRichrelevanceFeed() {
  /*return "eyJzdHJhdGVneU1lc3NhZ2UiOiJLdW5kZW4sIGRpZSBkaWVzZW4gQXJ0aWtlbCBrYXVmdGVuLCBrYXVmdGVuIGF1Y2giLCJyZWNvbW1lbmRlZFByb2R1Y3RJZHMiOlsiNDAyOTQwNTAwMDAwIiwiNDIwMzc3MDAwMDAwIiwiNDIwNzY1MTAwMDAwIiwiNDMwOTE4NTAwMDgwIiwiNDUwNjI4ODQwNTEwIiwiNDAyMzU5NDAwMDEwIiwiNDUxNjI4NzQzMTIwIiwiNDUwOTA3MTA0MDgwIl19";
   */
  const richrelevance = window.RR_DEBUG;
  if (
    richrelevance &&
    richrelevance.response &&
    richrelevance.response.placements &&
    richrelevance.response.placements[1]
  ) {
    const recommendedProducts =
      richrelevance.response.placements[1].recommendedProducts;
    const strategyMessage =
      richrelevance.response.placements[1].strategyMessage;
    const recommendedProductIds = recommendedProducts.map(
      (recommendedProduct) => recommendedProduct.id
    );
    const feed = { strategyMessage, recommendedProductIds };
    return btoa(JSON.stringify(feed));
  }
}
