export const BOOKING_IMGS = {
  bikeworld: [
    "https://res.cloudinary.com/db4fij86r/image/upload/v1632476949/consultants/unter_fm_bike_world_2021_onlineberater_daniel_berger_B2021_0001_tqk0ke.jpg",
    "https://res.cloudinary.com/db4fij86r/image/upload/v1632476697/consultants/unter_fm_bike_world_2021_onlineberater_daniel_berger_B2021_0002-min_wnvnjb.jpg"
  ],
  doitgarden: [
    "https://res.cloudinary.com/db4fij86r/image/upload/v1619439397/consultants/fm_live_videoberatung_doit_wankdorf_nicole_grau_4_21_P3A2309_tjymld.jpg",
    "https://res.cloudinary.com/db4fij86r/image/upload/v1619439451/consultants/fm_live_videoberatung_doit_wankdorf_nicole_grau_4_21_P3A2418_ipgvdr.jpg"
  ],
  melectronics: [
    "https://res.cloudinary.com/db4fij86r/image/upload/v1613571632/consultants/eahunbbkeofnactmc3nl.jpg",
    "/static/images/fachmarkt/popup_melectronics_termin.jpg"
  ],
  micasa: [
    "https://res.cloudinary.com/db4fij86r/image/upload/v1613570541/consultants/bo8f5ixderizksjdeohd.jpg",
    "https://res.cloudinary.com/db4fij86r/image/upload/v1613570547/consultants/k0quu9fia4vbnaiy4spk.jpg"
  ],
  sportxx: [
    "https://res.cloudinary.com/db4fij86r/image/upload/v1613571096/consultants/ch6bazosn8fkpgscfm7g.jpg",
    "https://res.cloudinary.com/db4fij86r/image/upload/v1613571102/consultants/idf3elwcez8syrzw7hns.jpg"
  ],
  sportx: [
    "https://res.cloudinary.com/db4fij86r/image/upload/w_500/v1693299009/consultants/beratungsheros_sportxx_8_23_Micha_Burkolter_P3A8623-1_1_cpjjec.png",
    "https://res.cloudinary.com/db4fij86r/image/upload/v1693311499/image_4_cftkdf.png"
  ],
  klubschule: [
    "https://res.cloudinary.com/db4fij86r/image/upload/v1609830211/consultants/cn2nct1sesbzi9krv5gj.jpg",
    "https://res.cloudinary.com/db4fij86r/image/upload/v1609830226/consultants/agil4a2ks1uyxi1ntyqo.jpg"
  ]
};
export const mBudget_IMGS = {
  de: "https://res.cloudinary.com/db4fij86r/image/upload/v1675503420/mbudget/mbudget_fonczd.png",
  fr: "https://res.cloudinary.com/db4fij86r/image/upload/v1675503420/mbudget/mbudget_FR_bhqoi0.png",
  it: "https://res.cloudinary.com/db4fij86r/image/upload/v1675503420/mbudget/mbudget_IT_sdlwpm.png"
};
