{
  "bannerTitle": "<b>Haben Sie weitere Fragen?</b><br />Wir können Sie bei einem Videogespräch kompetent beraten.",
  "bannerButton": "Video-Beratung starten",
  "cartButton": "Jetzt verbinden",
  "cartTitle": "<b>Haben Sie Fragen zu Ihrer Bestellung?</b><br /><b>Wir können Sie bei einem Videogespräch kompetent unterstützen.</b>",
  "configuratorStartPageTitle": "Hilfe beim Konfigurieren",
  "configuratorStartPageSubtitle": "Nutzen Sie zum Konfigurieren unsere Video-Beratung - bequem von zu hause aus.",
  "configuratorStartPageButton": "Videoberatung starten",
  "configuratorStartPageText": "Do you need help with the configuration? Use our video consultation: live, free of charge and without obligation.",
  "configuratorStartPageTextOffline": "Do you need help with the configuration? Use our video consultation: live, free of charge and without obligation.",
  "configuratorStartPageLink": "Start video consultation",
  "configuratorStartPageLinkOffline": "Book an appointment",
  "configuratorConsultant": "Consulting",
  "configuratorButton": "Hilfe? Unsere Video-Beratung unterstützt Sie",
  "mobileConfiguratorButton": "Zum Möbelkonfigurator per Video-Beratung",
  "nivekiTitle": "How can we help?",
  "nivekiButton": "Online Helpcenter",
  "nivekiButtonLink": {
    "doitgarden": "https://help.doitgarden.ch/hc/de",
    "melectronics": "https://help.melectronics.ch/hc/de",
    "micasa": "https://help.micasa.ch/hc/de",
    "sportxx": "https://help.sportx.ch/hc/de"
  },
  "nivekiButton1": "I have questions about my order",
  "nivekiButton1Link": {
    "doitgarden": "https://www.doitgarden.ch/de/cp/contactFormPage",
    "melectronics": "https://www.melectronics.ch/de/cp/contactFormPage",
    "micasa": "https://www.micasa.ch/de/cp/contactFormPage",
    "sportxx": "https://www.sportx.ch/de/cp/contactFormPage"
  },
  "nivekiButton1Subtext": "Problems with invoices, deliveries, repairs, etc.",
  "nivekiButton2": "I have questions about the online shop",
  "nivekiButton2Link": {
    "doitgarden": "https://help.doitgarden.ch/hc/de/categories/360002637600-Vor-meiner-Bestellung",
    "melectronics": "https://help.melectronics.ch/hc/de/categories/360002647099-Vor-meiner-Bestellung",
    "micasa": "https://help.micasa.ch/hc/de/categories/360002637760-Vor-meiner-Bestellung",
    "sportxx": "https://help.sportx.ch/hc/de/categories/360002647659-Vor-meiner-Bestellung"
  },
  "nivekiButton2Subtext": "Problems with vouchers, payment, etc.",
  "nivekiButton3": "I have questions about my local store",
  "nivekiButton3Link": {
    "doitgarden": "https://www.doitgarden.ch/de/cp/storefinder",
    "melectronics": "https://www.melectronics.ch/de/cp/storefinder",
    "micasa": "https://www.micasa.ch/de/cp/storefinder",
    "sportxx": "https://www.sportx.ch/de/cp/storefinder"
  },
  "nivekiButton3Subtext": "All you need to know about opening hours, stock, etc.",
  "nivekiPrimaryButton": "Videoberatung starten",
  "nivekiWidgetInfoLink": "So funktioniert die Videoberatung",
  "stickerButton": {
    "default": "Video-Beratung zum Produkt"
  },
  "themeSpecific": {
    "bikeworld": {
      "sizeTableButton": "Rahmengrösse via Video-Beratung ermitteln"
    }
  },
  "widgetAvatarTitle": {
    "default": "Video advice<br />in English or German"
  },
  "widgetAvatarTitleBooking": "Book<br />video advice",
  "widgetTitle": {
    "klubschule": "Unklarheiten? Kein Problem, wir sind für Sie da.",
    "default": "Video advice in English or German: Connected to our store"
  },
  "widgetTitleBooking": "Schedule free live video advice.",
  "widgetBodyBullet1": "You will not be visible.",
  "widgetBodyBooking": {
    "klubschule": "Unklarheiten? Kein Problem, wir sind für Sie da.",
    "default": "Get the same shopping experience at home as at a store!"
  },
  "widgetButton": {
    "default": "Start video advice"
  },
  "widgetButtonBooking": "Schedule appointment",
  "widgetHours": "__FROM__-__TO__",
  "widgetInfoLink": "Here's how",
  "widgetLive": "LIVE",
  "wishlistButton": "Produkte zeigen lassen",
  "widgetSundayToo": "Even on Sunday!",
  "wishlistTitle": "<b>Can we assist you in choosing in either English or German?</b><br />We can show you any product during a live video chat."
}
