{
  "bannerTitle": "<b>Vous avez d'autres questions?</b><br />Nous pouvons vous renseigner directement lors d'un appel vidéo.",
  "bannerButton": "Démarrer le conseil vidéo",
  "cartButton": "Se connecter",
  "cartTitle": "<b>Avez-vous des questions relatives à votre commande?</b><br /><b>Nous pouvons vous offrir un conseil compétent lors d’un entretien vidéo.</b>",
  "configuratorStartPageTitle": "Aide à la configuration",
  "configuratorStartPageSubtitle": "Utilisez notre conseil vidéo pour configurer vos meubles, confortablement depuis chez vous.",
  "configuratorStartPageButton": "Lancer le conseil vidéo",
  "configuratorStartPageText": "Besoin d’aide pour la configuration? Profitez de nos conseils vidéo: en direct, gratuitement et sans engagement.",
  "configuratorStartPageTextOffline": "Besoin d’aide pour la configuration? Profitez de nos conseils vidéo: en direct, gratuitement et sans engagement.",
  "configuratorStartPageLink": "Démarrez le conseil vidéo",
  "configuratorStartPageLinkOffline": "Je prends rendez-vous",
  "configuratorConsultant": "Conseil",
  "configuratorButton": "Besoin d’aide? Notre conseil vidéo est à votre service.",
  "mobileConfiguratorButton": "Conseil vidéo pour configurer ses meubles",
  "nivekiTitle": "En quoi pouvons-nous vous être utiles?",
  "nivekiButton1": "J’ai des questions sur ma commande",
  "nivekiButton1Link": {
    "doitgarden": "https://www.doitgarden.ch/fr/cp/contactFormPage",
    "melectronics": "https://www.melectronics.ch/fr/cp/contactFormPage",
    "micasa": "https://www.micasa.ch/fr/cp/contactFormPage",
    "sportxx": "https://www.sportx.ch/fr/cp/contactFormPage"
  },
  "nivekiButton1Subtext": "Des problèmes avec la facture, la livraison...",
  "nivekiButton2": "J’ai des questions sur la boutique en ligne",
  "nivekiButton2Link": {
    "doitgarden": "https://help.doitgarden.ch/hc/fr/categories/360002637600-Avant-ma-commande",
    "melectronics": "https://help.melectronics.ch/hc/fr/categories/360002647099-Avant-ma-commande",
    "micasa": "https://help.micasa.ch/hc/fr/categories/360002637760-Avant-ma-commande",
    "sportxx": "https://help.sportx.ch/hc/fr/categories/360002647659-Avant-ma-commande"
  },
  "nivekiButton2Subtext": "Problèmes avec des bons, le paiement, réparation...",
  "nivekiButton3": "J’ai des questions sur mon magasin",
  "nivekiButton3Link": {
    "doitgarden": "https://www.doitgarden.ch/fr/cp/storefinder",
    "melectronics": "https://www.melectronics.ch/fr/cp/storefinder",
    "micasa": "https://www.micasa.ch/fr/cp/storefinder",
    "sportxx": "https://www.sportx.ch/fr/cp/storefinder"
  },
  "nivekiButton3Subtext": "Tout sur les heures d’ouverture, les stocks en magasin...",
  "nivekiPrimaryButton": "Continuer vers les conseils produits",
  "stickerButton": {
    "default": "Conseil vidéo sur le produit"
  },
  "themeSpecific": {
    "bikeworld": {
      "sizeTableButton": "Détermination de la taille du cadre par consultation vidéo"
    }
  },
  "widgetAvatarTitle": {
    "default": "Conseil vidéo"
  },
  "widgetAvatarTitleBooking": "Réserver un conseil<br />vidéo",
  "widgetTitle": {
    "klubschule": "Des incertitudes ? Pas de problème, nous sommes là pour vous.",
    "default": "De chez vous, la même expérience d'achat qu'en magasin!"
  },
  "widgetTitleBooking": "Planifiez gratuitement un appel vidéo avec un conseiller.",
  "widgetBodyBullet1": "Votre caméra ne sera pas active",
  "widgetBodyBooking": {
    "klubschule": "Des incertitudes ? Pas de problème, nous sommes là pour vous.",
    "default": "Un contact direct avec votre magasin, sans bouger de chez vous !"
  },
  "widgetButton": {
    "default": "Lancer le conseil vidéo"
  },
  "widgetButtonBooking": "Planifier un appel vidéo",
  "widgetHours": "__FROM__ h à __TO__ h",
  "widgetInfoLink": "Comment ça marche ?",
  "widgetLive": "LIVE",
  "wishlistButton": "Voir les produits en direct",
  "widgetSundayToo": "Aussi Dimanche !",
  "wishlistTitle": "<b>Pouvons-nous vous aider dans votre choix?</b><br />Nous pouvons vous montrer tous les produits en direct lors d'un appel vidéo.",
  "mBudget": {
    "widget": {
      "title": "Avec l’abonnement mobile, jusqu’à 150.- de réduction pour votre nouveau smartphone!",
      "subtitle": "Faites-vous conseiller par vidéo. Votre caméra ne sera pas active",
      "cta": "Lancer le conseil vidéo et en profiter"
    },
    "banner": {
      "title": "<b>Avec l’abonnement mobile, jusqu’à 150.- de réduction pour votre nouveau smartphone!</b><br />Nous vous conseillons avec compétence lors d’un entretien vidéo.",
      "cta": "Lancer le conseil vidéo et en profiter"
    }
  }
}
