{
  "bannerTitle": "<b>Haben Sie weitere Fragen?</b><br />Wir können Sie bei einem Videogespräch kompetent beraten.",
  "bannerButton": "Video-Beratung starten",
  "cartButton": "Jetzt verbinden",
  "cartTitle": "<b>Haben Sie Fragen zu Ihrer Bestellung?</b><br /><b>Wir können Sie bei einem Videogespräch kompetent unterstützen.</b>",
  "configuratorStartPageTitle": "Hilfe beim Konfigurieren",
  "configuratorStartPageSubtitle": "Nutzen Sie zum Konfigurieren unsere Video-Beratung - bequem von zu hause aus.",
  "configuratorStartPageButton": "Videoberatung starten",
  "configuratorStartPageText": "Brauchen Sie Hilfe bei der Konfiguration? Nutzen Sie unsere Video-Beratung: live, kostenlos und unverbindlich.",
  "configuratorStartPageTextOffline": "Brauchen Sie Hilfe bei der Konfiguration? Nutzen Sie unsere Video-Beratung: kostenlos und unverbindlich.",
  "configuratorStartPageLink": "Jetzt Video-Beratung starten",
  "configuratorStartPageLinkOffline": "Jetzt Termin vereinbaren",
  "configuratorConsultant": "Beratung",
  "configuratorButton": "Hilfe? Unsere Video-Beratung unterstützt Sie",
  "mobileConfiguratorButton": "Zum Möbelkonfigurator per Video-Beratung",
  "nivekiTitle": "Wie können wir Ihnen helfen?",
  "nivekiButton": "Zum Online-Hilfecenter",
  "nivekiButtonLink": {
    "doitgarden": "https://help.doitgarden.ch/hc/de",
    "melectronics": "https://help.melectronics.ch/hc/de",
    "micasa": "https://help.micasa.ch/hc/de",
    "sportxx": "https://help.sportx.ch/hc/de"
  },
  "nivekiButton1": "Ich habe Fragen zu meiner Bestellung",
  "nivekiButton1Link": {
    "doitgarden": "https://www.doitgarden.ch/de/cp/contactFormPage",
    "melectronics": "https://www.melectronics.ch/de/cp/contactFormPage",
    "micasa": "https://www.micasa.ch/de/cp/contactFormPage",
    "sportxx": "https://www.sportx.ch/de/cp/contactFormPage"
  },
  "nivekiButton1Subtext": "Probleme mit Rechnung, Lieferung, Reparatur...",
  "nivekiButton2": "Ich habe Fragen zum Onlineshop",
  "nivekiButton2Link": {
    "doitgarden": "https://help.doitgarden.ch/hc/de/categories/360002637600-Vor-meiner-Bestellung",
    "melectronics": "https://help.melectronics.ch/hc/de/categories/360002647099-Vor-meiner-Bestellung",
    "micasa": "https://help.micasa.ch/hc/de/categories/360002637760-Vor-meiner-Bestellung",
    "sportxx": "https://help.sportx.ch/hc/de/categories/360002647659-Vor-meiner-Bestellung"
  },
  "nivekiButton2Subtext": "Probleme mit Gutscheinen, Zahlung...",
  "nivekiButton3": "Ich habe Fragen zu meiner Filiale",
  "nivekiButton3Link": {
    "doitgarden": "https://www.doitgarden.ch/de/cp/storefinder",
    "melectronics": "https://www.melectronics.ch/de/cp/storefinder",
    "micasa": "https://www.micasa.ch/de/cp/storefinder",
    "sportxx": "https://www.sportx.ch/de/cp/storefinder"
  },
  "nivekiButton3Subtext": "Alles über Öffnungszeiten, Filialbestände...",
  "nivekiPrimaryButton": "Video-Beratung starten",
  "nivekiWidgetInfoLink": "So funktioniert die Video-Beratung",
  "stickerButton": {
    "miocolor": "Video-Beratung vom Farbexperten",
    "default": "Video-Beratung zum Produkt"
  },
  "themeSpecific": {
    "bikeworld": {
      "sizeTableButton": "Rahmengrösse via Video-Beratung ermitteln"
    }
  },
  "widgetAvatarTitle": {
    "miocolor": "Video-Beratung Farbexperte",
    "default": "Video-Beratung"
  },
  "widgetAvatarTitleBooking": "Video-Beratung<br />buchen",
  "widgetTitle": {
    "klubschule": "Unklarheiten? Kein Problem, wir sind für Sie da.",
    "miocolor": "Live Video-Beratung von unseren Farbexperten",
    "default": "Von Zuhause aus das gleiche Einkaufserlebnis wie in der Filiale!"
  },
  "widgetTitleBooking": "Kostenlose Live Video-Beratung vereinbaren.",
  "widgetBodyBullet1": "Ihr Bild wird nicht übertragen",
  "widgetBodyBooking": {
    "klubschule": "Unklarheiten? Kein Problem, wir sind für Sie da.",
    "default": "Von Zuhause aus, das gleiche Einkaufserlebnis wie im Laden!"
  },
  "widgetButton": {
    "default": "Jetzt Video-Beratung starten"
  },
  "widgetButtonBooking": "Termin vereinbaren",
  "widgetHours": "__FROM__ - __TO__ Uhr",
  "widgetInfoLink": "So funktioniert es",
  "widgetLive": "LIVE",
  "wishlistButton": "Produkte zeigen lassen",
  "widgetSundayToo": "Auch Sonntags!",
  "wishlistTitle": "<b>Können wir Sie bei Ihrer Wahl unterstützen?</b><br />Alle Produkte können wir Ihnen bei einem Videogespräch live zeigen.",
  "mBudget": {
    "widget": {
      "title": "Mit Handy-Abo bis zu 150.- Rabatt fürs neue Smartphone!",
      "subtitle": "Lassen Sie sich per Video beraten. Ihr Bild wird nicht übertragen",
      "cta": "Jetzt Video-Beratung starten und profitieren"
    },
    "banner": {
      "title": "<b>Mit Handy-Abo bis zu 150.- Rabatt fürs neue Smartphone!</b><br />Wir können Sie bei einem Videogespräch kompetent beraten.",
      "cta": "Jetzt Video-Beratung starten und profitieren"
    }
  }
}
