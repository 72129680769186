import { logEvent, loadTexts, isSmartphonePage } from "./widget";
import { calendar, close as closeIcon, info, notSeen, noSound } from "./icons";
import { BOOKING_IMGS, mBudget_IMGS } from "./constants";
import { sendHotjarEvent } from "./utilities";

export function createWidget({
  consultant,
  theme,
  lang,
  variant,
  hidden = false,
  booking,
  bookingLink,
  landingPageUrl,
  tenant,
  position,
  widgetType = "default", // default, multiple1, multiple2
  popupVideoUrl = null,
  from,
  url
}) {
  if (window?.BH?.widgetIsDisabled) return;

  const texts = loadTexts(lang);

  const smartphonePage = isSmartphonePage(url);

  let avatarImage = booking
    ? BOOKING_IMGS[theme][0]
    : consultant
      ? consultant.imageAvatar
      : null;
  let branchImage = smartphonePage
    ? mBudget_IMGS[lang]
    : booking
      ? BOOKING_IMGS[theme][1]
      : consultant
        ? consultant.imageBranch
        : null;

  if (avatarImage.indexOf("https://") === 0) {
    avatarImage = avatarImage.replace("upload/", "upload/w_160/");
  } else {
    avatarImage = window.BH_URL + avatarImage;
  }

  if (branchImage.indexOf("https://") === 0) {
    branchImage = branchImage.replace("upload/", "upload/w_800/");
  } else {
    branchImage = window.BH_URL + branchImage;
  }

  // For bikeworld to sportxx fallback during winter
  if (from === "bikeworld") {
    avatarImage =
      "https://res.cloudinary.com/db4fij86r/image/upload/v1632476949/consultants/unter_fm_bike_world_2021_onlineberater_daniel_berger_B2021_0001_tqk0ke.jpg";
    branchImage =
      "https://res.cloudinary.com/db4fij86r/image/upload/v1632476697/consultants/unter_fm_bike_world_2021_onlineberater_daniel_berger_B2021_0002-min_wnvnjb.jpg";
    landingPageUrl = "https://www.bikeworld.ch/de/cp/videoberatung";
  }

  /*function redirectToCall() {
    let shopUrl = document.location.href.split("#")[0];
    if (shopUrl.match("localhost") || shopUrl.match("ngrok")) {
      shopUrl = "https://qual-www.micasa.ch/de/p/405750100000/diener";
    }
    let videoCallUrl = booking
      ? bookingLink
      : `${
          window.BH_URL
        }/${theme}/${lang}?connect=auto&url=${encodeURIComponent(
          shopUrl
        )}&consultantId=${consultant._id}`;

    window.open(videoCallUrl, "_blank", "noopener noreferrer");
  }*/

  const popupPrimaryButtonUrl = getPopupPrimaryButtonUrl(
    booking,
    bookingLink,
    theme,
    lang,
    getShopUrl(),
    consultant
  );

  const div = document.createElement("div");
  div.className = "beratungshero--root";
  if (hidden) {
    div.style.display = "none";
  }

  const nivekiEnabled = tenant.nivekiEnabled && !!window.BH.nivekiEnabled;

  if (popupVideoUrl !== null && !booking) {
    div.innerHTML = `
    <div class="beratungshero--popup beratungshero--popup--video ${
      position === "left" ? "beratungshero--popup--left" : ""
    }">
      <div id="beratungshero-popup-content">
      <div class="beratungshero-popup__video">
        <video class="js-beratungshero-popup-video"
          src="${window.BH_URL + popupVideoUrl}"
          autoplay="true" playsinline="true" webkit-playsinline="true" muted loop>
        </video>
        <div class="beratungshero--close beratungshero-popup__video__close">${closeIcon}</div>
        <div class="beratungshero--header-live beratungshero-popup__video__live">LIVE</div>
        <div class="beratungshero-popup__video__button-no-sound js-beratungshero-popup-no-sound">${noSound}</div>
        <div class="beratungshero-popup__video__button-wrapper">
          <a id="beratungshero-popup-video" class="js-beratungshero-popup-primary-button beratungshero--more beratungshero-popup__video__button cursor-pointer" href="${getPopupPrimaryButtonUrl()}" target="_blank" rel="noreferrer noopener">
            Video-Beratung starten
          </a>
        </div>
        </div>

        <!--<div class="beratungshero--header lazy" style="background-image: url('${branchImage}');">
          <div class="beratungshero--close">${closeIcon}</div>
          <div class="beratungshero--header-live">LIVE</div>
        </div>-->

        <div class="beratungshero--links-container" ${
          theme === "klubschule" ? `style="display: none;"` : ""
        }>
          <div class="beratungshero--link">
          </div>
          <div id="beratungshero-link-how-it-works" class="beratungshero--link">
            <a href=${landingPageUrl} target="_blank" rel="noopener">${info}${
              texts.widgetInfoLink
            }</a>
          </div>
        </div>
      </div>
    </div>`;
  } else if (variant === 1 || booking) {
    // Large PopUp
    div.innerHTML = `
    <div class="beratungshero--popup ${
      booking ? "beratungshero--booking" : ""
    } ${position === "left" ? "beratungshero--popup--left" : ""}">
      <div id="beratungshero-popup-content">
        <div class="beratungshero--header lazy" style="background-image: url('${branchImage}');">
          <div class="beratungshero--close">${closeIcon}</div>
          ${booking ? "" : `<div class="beratungshero--header-live">LIVE</div>`}
        </div>
        <div class="beratungshero--title" ${
          nivekiEnabled ? 'style="margin-bottom: 0px"' : ""
        }>
          ${
            smartphonePage
              ? texts.mBudget.widget.title
              : booking
                ? texts.widgetTitleBooking
                : nivekiEnabled
                  ? texts.nivekiTitle
                  : texts.widgetTitle[theme] || texts.widgetTitle.default
          }
        </div>
        ${!nivekiEnabled && theme !== "miocolor" ? getNotSeenPart() : ""}
        ${theme === "miocolor" ? getMiocolorPart() : ""}
        <a id="beratungshero-popup-video" class="js-beratungshero-popup-primary-button beratungshero--more cursor-pointer" href="${getPopupPrimaryButtonUrl()}" target="_blank" rel="noreferrer noopener" style="${
          nivekiEnabled ? "margin-top: 10px; margin-bottom: 10px" : ""
        }">
          ${booking ? calendar : ""}
          ${
            booking
              ? texts.widgetButtonBooking
              : nivekiEnabled
                ? texts.nivekiPrimaryButton
                : smartphonePage
                  ? texts.mBudget.widget.cta
                  : texts.widgetButton[theme] || texts.widgetButton.default
          }
        </a>
        ${nivekiEnabled ? getNotSeenPart() : ""}
        ${nivekiEnabled ? getNivekiButton() : ""}
        <div class="beratungshero--links-container" ${
          theme === "klubschule" ? `style="display: none;"` : ""
        }>
          <div class="beratungshero--link">
          </div>
          <div id="beratungshero-link-how-it-works" class="beratungshero--link">
            <a href=${landingPageUrl} target="_blank" rel="noopener">${info}${
              nivekiEnabled ? texts.nivekiWidgetInfoLink : texts.widgetInfoLink
            }</a>
          </div>
        </div>
      </div>
    </div>
  `;
  } else {
    div.innerHTML = `
    <div class="beratungshero--popup beratungshero--popup--mobile ${
      booking ? "beratungshero--booking" : ""
    } ${position === "left" ? "beratungshero--popup--left" : ""}">
      <div id="beratungshero-popup-content">
        <div class="beratungshero--close--mobile">${closeIcon}</div>
        <div style="display: flex; position:relative">
          <div style="width: 66.66%">
            <div style="padding: 12px 14px 12px 16px">
              <div class="beratungshero--title--mobile">
                ${
                  smartphonePage
                    ? texts.mBudget.widget.title
                    : texts.widgetTitle[theme] || texts.widgetTitle.default
                }
              </div>
              <a id="beratungshero-popup-video" class="js-beratungshero-popup-primary-button beratungshero--more--mobile cursor-pointer" href="${getPopupPrimaryButtonUrl()}" target="_blank" rel="noreferrer noopener">
                ${
                  smartphonePage
                    ? texts.mBudget.widget.cta
                    : texts.widgetButton[theme] || texts.widgetButton.default
                }
              </a>
            </div>
          </div>
          <div style="width: 33.33%; background-image: url(${branchImage}); -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 20% 100%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 20% 100%); border-radius: 0 4px 0 0; background-size: cover;
        background-position: center;"></div>
        </div>
        <div class="beratungshero--links-container--mobile" ${
          theme === "klubschule" ? `style="display: none;"` : ""
        }>
          <div class="beratungshero--link--mobile">
              <span>${notSeen} ${
                smartphonePage
                  ? texts.mBudget.widget.subtitle
                  : texts.widgetBodyBullet1
              }</span>
          </div>
          <div id="beratungshero-link-how-it-works" class="beratungshero--link--mobile">
            <a href=${landingPageUrl} target="_blank" rel="noopener">${info}${
              texts.widgetInfoLink
            }</a>
          </div>
        </div>
      </div>
    </div>
  `;
  }

  div.innerHTML += getWidget();

  document.body.appendChild(div);

  const avatar = document.querySelector(".beratungshero--avatar");
  avatar.onclick = togglePopup;

  let closeButton = document.querySelector(".beratungshero--close");

  if (!closeButton) {
    closeButton = document.querySelector(".beratungshero--close--mobile");
  }
  closeButton.onclick = function () {
    togglePopup();
    if (popupVideoUrl !== null) {
      const video = document.querySelector(".js-beratungshero-popup-video");
      video.muted = true;
      const activateSoundButton = document.querySelector(
        ".js-beratungshero-popup-no-sound"
      );
      setTimeout(() => {
        activateSoundButton.style.display = "flex";
      }, 1000);
    }
  };

  const videoCallButton = document.querySelector("#beratungshero-popup-video");
  videoCallButton.onclick = function (e) {
    if (booking) {
      logEvent("BUTTON_APPOINTMENT_CLICKED");
    } else {
      logEvent("BUTTON_CLICKED", { url: document.location.href });
      sendHotjarEvent("beratungshero_button_clicked");
    }
    /*if (!booking && tenant.nivekiEnabled && !isMobile()) {
      e.preventDefault(); // Don't redirect to call
      logEvent("POPUP_NIVEKI");
      showNiveki();
    }*/
  };

  const howItWorksLink = document.querySelector(
    "#beratungshero-link-how-it-works"
  );
  howItWorksLink.onclick = function () {
    logEvent("LINK_HOW_IT_WORKS_CLICKED");
  };

  const nivekiButton = document.querySelector("#beratungshero-niveki-button");
  if (nivekiButton) {
    nivekiButton.onclick = function () {
      logEvent("BUTTON_POPUP_NIVEKI_CLICKED");
    };
  }

  const activateSoundButton = document.querySelector(
    ".js-beratungshero-popup-no-sound"
  );
  if (activateSoundButton) {
    activateSoundButton.onclick = activateSoundButtonClicked;
  }

  function togglePopup() {
    const popup = document.querySelector(".beratungshero--popup");

    if (window.BH.widgetClosed) {
      if (popupVideoUrl !== null) {
        loadVideoFromStart();
      }
      lazyLoadPopupImage();
      popup.style.transform = "scale(1)";
      avatar
        .querySelector(".beratungshero--avatar-img")
        .classList.remove("beratungshero--avatar-pulse");
      logEvent("WIDGET_OPENED");
    } else {
      popup.style.transform = "scale(0)";
      avatar
        .querySelector(".beratungshero--avatar-img")
        .classList.add("beratungshero--avatar-pulse");
      logEvent("WIDGET_CLOSED");
    }

    window.BH.widgetClosed = !window.BH.widgetClosed;
  }

  function showNiveki() {
    const popupContent = document.querySelector("#beratungshero-popup-content");
    popupContent.innerHTML = getNivekiContent();

    const nivekiButton1 = document.querySelector(
      "#beratungshero-niveki-button-1"
    );
    nivekiButton1.onclick = function () {
      logEvent("BUTTON_POPUP_TRIAGE_1_CLICKED");
      openInNewTab(texts.nivekiButton1Link[theme]);
    };

    const nivekiButton2 = document.querySelector(
      "#beratungshero-niveki-button-2"
    );
    nivekiButton2.onclick = function () {
      logEvent("BUTTON_POPUP_TRIAGE_2_CLICKED");
      openInNewTab(texts.nivekiButton2Link[theme]);
    };

    const nivekiButton3 = document.querySelector(
      "#beratungshero-niveki-button-3"
    );
    nivekiButton3.onclick = function () {
      logEvent("BUTTON_POPUP_TRIAGE_3_CLICKED");
      openInNewTab(texts.nivekiButton3Link[theme]);
    };

    const nivekiButtonVideo = document.querySelector(
      "#beratungshero-niveki-button-video"
    );
    nivekiButtonVideo.onclick = function () {
      logEvent("BUTTON_POPUP_TRIAGE_CALL_CLICKED");
    };

    let closeButton = document.querySelector(".beratungshero--close");
    if (!closeButton) {
      closeButton = document.querySelector(".beratungshero--close--mobile");
    }
    closeButton.onclick = togglePopup;
  }

  function getWidget() {
    let multipleConsultantAvatars;
    if (theme === "melectronics") {
      multipleConsultantAvatars = [
        "https://res.cloudinary.com/db4fij86r/image/upload/v1629181421/consultants/fotos_onlineberater_melectronics_sch%C3%B6nb%C3%BChl_002-min_kn9l3z.jpg",
        "https://res.cloudinary.com/db4fij86r/image/upload/v1629181466/consultants/fotos_onlineberater_melectronics_sch%C3%B6nb%C3%BChl_001-min_ajtuay.jpg"
      ];
    } else if (theme === "micasa") {
      multipleConsultantAvatars = [
        "https://res.cloudinary.com/db4fij86r/image/upload/v1612423871/consultants/a8utsbdbio1ki7sikfqg.jpg",
        "https://res.cloudinary.com/db4fij86r/image/upload/v1613570250/consultants/zwnk5rtfbqyjnxqyb7tg.jpg"
      ];
    }

    return `<button class="beratungshero--button-reset beratungshero--avatar beratungshero--avatar--${widgetType} ${
      position === "left" ? "beratungshero--avatar--left" : ""
    }">
      <div class="beratungshero--avatar-container">
        ${
          widgetType === "multiple1"
            ? `<div class="beratungshero--avatar-img beratungshero--avatar-img--sm beratungshero--avatar-img--multiple1-left beratungshero--avatar-pulse">
          <img src="${multipleConsultantAvatars[0]}"  alt="consultant"/>
        </div>
        <div class="beratungshero--avatar-img beratungshero--avatar-img--sm beratungshero--avatar-img--multiple1-right beratungshero--avatar-pulse">
          <img src="${multipleConsultantAvatars[1]}"  alt="consultant"/>
        </div>`
            : ""
        }
        ${
          widgetType === "multiple2"
            ? `<div class="beratungshero--avatar-img--sm beratungshero--avatar-img--multiple2-top-right">
          <img src="${multipleConsultantAvatars[0]}"  alt="consultant"/>
        </div>
        <div class="beratungshero--avatar-img--xs beratungshero--avatar-img--multiple2-right">
          <img src="${multipleConsultantAvatars[1]}"  alt="consultant"/>
        </div>`
            : ""
        }
        <div class="beratungshero--avatar-img beratungshero--avatar-pulse">
          <img src="${avatarImage}"  alt="consultant"/>
        </div>
        ${
          booking
            ? ""
            : `<div class="beratungshero--avatar-live">${texts.widgetLive}</div>`
        }
      </div>
      <div class="beratungshero--avatar-title">${
        booking
          ? texts.widgetAvatarTitleBooking
          : texts.widgetAvatarTitle[theme] || texts.widgetAvatarTitle.default
      }</div>
      ${
        !booking && isSunday()
          ? `<div class="beratungshero--avatar-additional-title">${texts.widgetSundayToo}</div>`
          : ""
      }
      ${
        !booking &&
        !isSunday() &&
        getFromOperatingHour(tenant) &&
        getToOperatingHour(tenant)
          ? `<div class="beratungshero--avatar-additional-title">${texts.widgetHours
              .replace("__FROM__", getFromOperatingHour(tenant))
              .replace("__TO__", getToOperatingHour(tenant))}</div>`
          : ""
      }
    </button>`;
  }

  function getNivekiContent() {
    return `
    <div ${variant === 2 ? 'class="beratungshero-content--mobile"' : ""}>
      <div style="position: relative; height: 40px;">
        <div class="beratungshero--close">${closeIcon}</div>
      </div>
      <div class="beratungshero--title">
        ${texts.nivekiTitle}
      </div>
      <div class="beratungshero-niveki-button-wrapper">
        <button id="beratungshero-niveki-button-1" class="beratungshero-niveki-button cursor-pointer">
          <span>${texts.nivekiButton1}</span>
          <div>${texts.nivekiButton1Subtext}</div>
        </button>
      </div>
      <div class="beratungshero-niveki-button-wrapper">
        <button id="beratungshero-niveki-button-2" class="beratungshero-niveki-button cursor-pointer">
          <span>${texts.nivekiButton2}</span>
          <div>${texts.nivekiButton2Subtext}</div>
        </button>
      </div>
      <div class="beratungshero-niveki-button-wrapper">
        <button id="beratungshero-niveki-button-3" class="beratungshero-niveki-button cursor-pointer">
          <span>${texts.nivekiButton3}</span>
          <div>${texts.nivekiButton3Subtext}</div>
        </button>
      </div>
      <a id="beratungshero-niveki-button-video" class="js-beratungshero-popup-primary-button beratungshero--more cursor-pointer" href="${getPopupPrimaryButtonUrl()}" target="_blank" rel="noreferrer noopener">
        ${texts.nivekiPrimaryButton}
      </a>
    </div>`;
  }

  function getPopupPrimaryButtonUrl() {
    const urlParams = new URLSearchParams();

    if (booking) {
      return `${bookingLink}?${urlParams.toString()}`;
    } else {
      urlParams.append("connect", "auto");
      urlParams.append("url", encodeURIComponent(getShopUrl()));
      urlParams.append("consultantId", consultant._id);

      if (from) {
        urlParams.append("from", from);
      }

      return `${window.BH_URL}/${theme}/${lang}?${urlParams.toString()}`;
    }
  }

  function getNotSeenPart() {
    return `<div class="beratungshero--body">
    <div class="beratungshero-list" ${
      nivekiEnabled ? 'style="margin-top: 0px"' : ""
    }>
      <div class="beratungshero--list-item">
        ${
          smartphonePage
            ? texts.mBudget.widget.subtitle
            : booking
              ? texts.widgetBodyBooking[theme] ||
                texts.widgetBodyBooking.default
              : `${notSeen} ${
                  smartphonePage
                    ? texts.mBudget.widget.subtitle
                    : texts.widgetBodyBullet1
                }`
        }
      </div>
    </div>
  </div>`;
  }

  function activateSoundButtonClicked() {
    logEvent("BUTTON_ACTIVATE_SOUND_CLICKED");
    const video = document.querySelector(".js-beratungshero-popup-video");
    video.muted = false;
    const activateSoundButton = document.querySelector(
      ".js-beratungshero-popup-no-sound"
    );
    activateSoundButton.style.display = "none";
  }

  function getMiocolorPart() {
    return `
      <div style="text-align: center">
        Unser spezialisierter Farbexperte berät Sie live per Video-Beratung über unser Farbangebot.
      </div>`;
  }

  function getNivekiButton() {
    return `<a id="beratungshero-niveki-button" class="beratungshero-new-niveki-button cursor-pointer" href="${texts.nivekiButtonLink[theme]}" target="_blank" rel="noreferrer noopener">
        ${texts.nivekiButton}
    </a>`;
  }

  logOptimizeEventWidgetShown18to20(booking);
  logOptimizeEventWidgetShown(booking);
}

function openInNewTab(url) {
  window.open(url, "_blank", "noopener noreferrer");
}

function logOptimizeEventWidgetShown18to20(booking) {
  if (booking) {
    return;
  }
  const date = new Date();
  // Do not log on friday (it's until 21.30)
  if (date.getDay() === 5) {
    return;
  }
  const hour = date.getHours();
  // Do not log before 18:00 or after 20:00
  if (hour < 18 || hour > 20) {
    return;
  }

  window.datalayer = window.datalayer || [];
  window.dataLayer.push({ event: "optimize.widget.shown.18.to.20" });
}

function logOptimizeEventWidgetShown(booking) {
  if (booking) {
    return;
  }

  window.datalayer = window.datalayer || [];
  window.dataLayer.push({ event: "optimize.widget.shown" });
}

function isMobile() {
  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );
  return vw <= 640;
}

function isSunday() {
  const today = new Date();
  return today.getDay() === 0;
}

function getFromOperatingHour(tenant) {
  const today = new Date();
  if (
    tenant.operatingHours[today.getDay()] &&
    tenant.operatingHours[today.getDay()].length === 1 &&
    tenant.operatingHours[today.getDay()][0].from
  ) {
    return getDisplayTime(tenant.operatingHours[today.getDay()][0].from);
  }
  return null;
}

function getToOperatingHour(tenant) {
  const today = new Date();
  if (
    tenant.operatingHours[today.getDay()] &&
    tenant.operatingHours[today.getDay()].length === 1 &&
    tenant.operatingHours[today.getDay()][0].to
  ) {
    return getDisplayTime(tenant.operatingHours[today.getDay()][0].to);
  }
  return null;
}

function getDisplayTime(time) {
  const splitTime = time.split(":");
  let timeString = removeLeadingZeros(splitTime[0]);
  if (splitTime.length > 1) {
    if (removeLeadingZeros(splitTime[1]) > 0) {
      timeString += `:${splitTime[1]}`;
    }
  }
  return timeString;
}

function removeLeadingZeros(numString) {
  return parseInt(numString, 10);
}

function getShopUrl() {
  let shopUrl = document.location.href.split("#")[0];
  if (shopUrl.match("localhost") || shopUrl.match("ngrok")) {
    shopUrl = "https://qual-www.micasa.ch/de/p/405750100000/diener";
  }
  return shopUrl;
}

export function lazyLoadPopupImage() {
  const image = document.querySelector(".lazy");
  if (image) {
    image.classList.remove("lazy");
  }
}

export function loadVideoFromStart() {
  const video = document.querySelector(".js-beratungshero-popup-video");
  if (video) {
    video.currentTime = 0;
  }
}
