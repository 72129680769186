import { logEvent } from "../../widget";
import { sendHotjarEvent } from "../../utilities";

/**
 * Create a sticker a tag to inject into the dom.
 * @param {Object} texts
 * @param {string} texts.stickerButton
 * @param {string} texts.widgetLive
 * @param {string} tenant
 * @returns {string}
 * @example
 * getInnerHtmlContent(texts, tenant);
 * @example
 * getInnerHtmlContent(texts, "bikeworld");
 */
function getInnerHtmlContent(texts, tenant) {
  return `<span>${
    texts.stickerButton[tenant] || texts.stickerButton.default
  }</span><span class="beratungshero--sticker-live">${
    texts.widgetLive
  }</span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path></svg>`;
}

/**
 * Create a sticker a tag to inject into the dom.
 * @param {Object} texts
 * @param {string} texts.stickerButton
 * @param {string} texts.widgetLive
 * @param {string} tenant
 * @returns {HTMLElement}
 * @example
 * createStickerElement(texts, tenant);
 * @example
 * createStickerElement(texts, "bikeworld");
 */
export default function createStickerElement(texts, tenant) {
  if (!tenant) throw new Error("tenant is required");
  if (!texts) throw new Error("texts is required");
  if (!texts.stickerButton) throw new Error("texts.stickerButton is required");
  if (!texts.widgetLive) throw new Error("texts.widgetLive is required");

  const a = document.createElement("a");
  a.className = "js__beratungshero--sticker beratungshero--sticker";
  a.target = "_blank";
  a.rel = "noopener noreferrer";
  a.innerHTML = getInnerHtmlContent(texts, tenant);
  a.onclick = function () {
    logEvent("BUTTON_STICKER_CLICKED");
    sendHotjarEvent("beratungshero_button_sticker_clicked");
  };

  return a;
}
