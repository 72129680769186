import { getElement } from "../utilities";
import { logEvent, loadTexts } from "../widget";

const MOBILE_CONFIGURATOR_CLASS = "beratungshero--mobile-configurator";

export async function createMobileConfiguratorElement({
  url,
  consultant,
  theme,
  lang
}) {
  const texts = loadTexts(lang);
  const a = document.createElement("a");
  a.className = MOBILE_CONFIGURATOR_CLASS;
  a.target = "_blank";
  a.rel = "noopener noreferrer";
  a.href = `${window.BH_URL}/${theme}/${lang}?url=${encodeURIComponent(
    url
  )}&consultantId=${consultant._id}&connect=auto&from=mobile-configurator`;
  a.innerHTML = `
  <span>${texts.mobileConfiguratorButton}</span>
  `;

  const anchorElement = await getElement(".detail-showcase--img-teaser-box");

  if (anchorElement) {
    insertElement(anchorElement, a);
  }
}

function insertElement(anchorElement, elementToInsert) {
  anchorElement.parentNode.insertBefore(elementToInsert, anchorElement);
  logEvent("MOBILE_CONFIGURATOR_SHOWN");

  const element = document.querySelector(`.${MOBILE_CONFIGURATOR_CLASS}`);
  if (element) {
    element.onclick = function () {
      logEvent("BUTTON_MOBILE_CONFIGURATOR_CLICKED");
    };
  }
}

export function isConfigurableProduct(url) {
  return url.match(
    /\/(flexa|diener|harrison|indivani|opus|modul|navier|flexcube|till|seven)/
  );
}
