import { TENANTS } from "../constants";

/**
 * Verifies the elements are defined and the config is valid. If not, an error will be thrown.
 * @param {HTMLElement} anchor
 * @param {object} config
 * @param {string} config.lang
 * @param {("bikeworld"|"doitgarden"|"micasa"|"melectronics"|"sportx")} config.tenant
 * @returns {void}
 * @example
 * verifyInputs(anchor, config);
 * @example
 * verifyInputs(anchor, { lang: "de", tenant: "bikeworld" });
 */
export default function verifyInputs(anchor, config) {
  if (!anchor) throw new Error("No anchor element provided.");
  if (!config) throw new Error("No config object provided.");
  if (!config.lang) throw new Error("No language provided.");
  if (!config.tenant) throw new Error("No tenant provided.");
  if (!TENANTS.includes(config.tenant))
    throw new Error("Invalid tenant provided.");
}
