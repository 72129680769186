import { loadTexts, logEvent, isSmartphonePage } from "./widget";
import { camera, heart, notSeen, videoConsultation } from "./icons";
import { getElement } from "./utilities";

export async function createBanner({ url, consultant, theme, lang }) {
  if (window?.BH?.bannerIsDisabled) return;

  const texts = loadTexts(lang);
  const smartphone = isSmartphonePage(url);

  const div = window?.BH?.newBanner
    ? createNewPdpBannerDiv({
        url,
        consultant,
        theme,
        lang,
        text: smartphone ? texts.mBudget.banner.title : texts.bannerTitle,
        buttonText: `<span>${
          smartphone ? texts.mBudget.banner.cta : texts.bannerButton
        }</span>`
      })
    : createOldPdpBannerDiv({
        url,
        consultant,
        theme,
        lang,
        text: smartphone ? texts.mBudget.banner.title : texts.bannerTitle,
        buttonText: `<span>${
          smartphone ? texts.mBudget.banner.cta : texts.bannerButton
        }</span>`
      });

  const reviews = await getElement("#reviews");

  if (reviews) {
    reviews.parentNode.insertBefore(div, reviews);

    attachBannerClickEvent("BUTTON_BANNER_CLICKED");
  }
}

export async function createWishlistBanner({ url, consultant, theme, lang }) {
  if (window?.BH?.wishlistBannerDisabled) return;

  const texts = loadTexts(lang);
  const banner = createBannerDiv({
    url,
    consultant,
    theme,
    lang,
    text: texts.wishlistTitle,
    buttonText: `<span>${texts.wishlistButton}</span>`
  });
  const wishlist = await getElement(".wishlist.skel--root");

  if (wishlist) {
    const wishlistContainer = document.createElement("div");
    banner.className = "wishlist--container";
    wishlistContainer.appendChild(banner);
    wishlist.appendChild(wishlistContainer);

    attachBannerClickEvent("BUTTON_WISHLIST_CLICKED");
  }
}

export async function createCartBanner({ url, consultant, theme, lang }) {
  const texts = loadTexts(lang);
  const banner = createBannerDiv({
    url,
    consultant,
    theme,
    lang,
    text: texts.cartTitle,
    buttonText: `<span>${texts.cartButton}</span>`
  });
  const slider = await getElement(".p-cart--row__last");

  if (slider) {
    slider.parentNode.insertBefore(banner, slider);

    attachBannerClickEvent("BUTTON_CART_CLICKED");
  }
}

function createBannerDiv({ url, consultant, theme, lang, text, buttonText }) {
  if (window?.BH?.bannerIsDisabled) return;

  const texts = loadTexts(lang);

  let avatarImage = consultant.imageAvatar;

  if (avatarImage.indexOf("https://") === 0) {
    avatarImage = avatarImage.replace("upload/", "upload/w_240/");
  } else {
    avatarImage = window.BH_URL + avatarImage;
  }

  const div = document.createElement("div");
  div.className =
    "beratungshero--root p-product-detail--item p-product-detail--item__details detail-navigation-section";
  div.innerHTML = `
  <div class="beratungshero--banner">
    <div class="beratungshero--avatar-container">
      <img src="${avatarImage}" />
      <div class="beratungshero--avatar-live">${texts.widgetLive}</div>
    </div>
    <div class="beratungshero--body">
      <div class="beratungshero--left">
        <div class="beratungshero--text">
          <div class="beratungshero--title">
            ${text}
          </div>
          <div class="beratungshero-list">
            <div class="beratungshero--list-item">
              ${notSeen} ${texts.widgetBodyBullet1}
            </div>
          </div>
        </div>
      </div>
      <div class="beratungshero--buttons beratungshero--buttons-center">
        <a id="beratungshero-banner-video" class="beratungshero--more" href="${
          window.BH_URL
        }/${theme}/${
          lang === "en" ? "de" : lang
        }?connect=auto&url=${encodeURIComponent(url)}&consultantId=${
          consultant._id
        }" target="_blank" rel="noopener noreferrer">
      ${buttonText}
    </a>
      </div>
    </div>
  </div>
  `;

  return div;
}

export function createOldPdpBannerDiv({
  url,
  consultant,
  theme,
  lang,
  text,
  buttonText
}) {
  const texts = loadTexts(lang);

  let avatarImage = consultant.imageAvatar;

  if (avatarImage.indexOf("https://") === 0) {
    avatarImage = avatarImage.replace("upload/", "upload/w_240/");
  } else {
    avatarImage = window.BH_URL + avatarImage;
  }

  const urlParams = new URLSearchParams();

  urlParams.append("connect", "auto");
  urlParams.append("url", encodeURIComponent(url));
  urlParams.append("consultantId", consultant._id);

  const finalLang = lang === "en" ? "de" : lang;
  const constructedUrl = `${
    window.BH_URL
  }/${theme}/${finalLang}?${urlParams.toString()}`;

  const div = document.createElement("div");
  div.className =
    "beratungshero--root p-product-detail--item p-product-detail--item__details detail-navigation-section";
  div.innerHTML = `
  <div class="beratungshero--banner beratungshero--banner--pdp">
    <div class="beratungshero--avatar-container">
      <img src="${avatarImage}" />
      <div class="beratungshero--avatar-live">${texts.widgetLive}</div>
    </div>
    <div class="beratungshero--body beratungshero--body--pdp">
      <div class="beratungshero--left">
        <div class="beratungshero--text">
          <div class="beratungshero--title">
            ${text}
          </div>
          <div class="beratungshero-list">
            <div class="beratungshero--list-item beratungshero--list-item--pdp">
              ${notSeen} ${texts.widgetBodyBullet1}
            </div>
          </div>
        </div>
      </div>
      <div class="beratungshero--buttons beratungshero--buttons--pdp beratungshero--buttons-center">
        <a id="beratungshero-banner-video" class="beratungshero--more" href="${constructedUrl}" target="_blank" rel="noopener noreferrer">
      ${buttonText}
    </a>
      </div>
    </div>
  </div>
  `;

  return div;
}

export function createNewPdpBannerDiv({
  url,
  consultant,
  theme,
  lang,
  text,
  buttonText
}) {
  const texts = loadTexts(lang);

  let image = consultant.imageBranch;

  if (image.indexOf("https://") === 0) {
    image = image.replace("upload/", "upload/w_540/");
  } else {
    image = window.BH_URL + image;
  }

  const div = document.createElement("div");
  div.className = "beratungshero-banner-wrapper";
  div.innerHTML = `
  <div class="beratungshero-banner-background"></div>
  <div class="beratungshero-banner">
    <div class="beratungshero-banner__img" style="background-image: url(${image})"></div>
    <div class="beratungshero-banner__content">
      <div class="beratungshero-banner__title">
        Hier und jetzt: unsere Beratung per Video
      </div>
      <div class="beratungshero-banner__list">
        <div class="beratungshero-banner__list__item">
          ${videoConsultation} Video-Beratung aus der Filiale
        </div>
        <div class="beratungshero-banner__list__item">
          ${heart} Wunschprodukte zeigen lassen
        </div>
        <div class="beratungshero-banner__list__item">
          ${camera} Ihr Bild wird nicht übertragen
        </div>
      </div>
      <a id="beratungshero-banner-video" class="beratungshero-banner__button" href="${
        window.BH_URL
      }/${theme}/${
        lang === "en" ? "de" : lang
      }?connect=auto&url=${encodeURIComponent(url)}&consultantId=${
        consultant._id
      }" target="_blank" rel="noopener noreferrer">
      <div class="beratungshero-banner__button__live">
        <div class="beratungshero-banner__button__live__green-bubble">
        </div>
        LIVE
      </div>
      Video-Beratung starten
    </a>
    </div>
  </div>
  `;

  return div;
}

export function attachBannerClickEvent(event) {
  const moreLink = document.querySelector("#beratungshero-banner-video");
  moreLink.onclick = function () {
    logEvent(event);
  };
}
