import { notSeen } from "../../icons";

/**
 * Get the inner html content for the wishlist banner.
 * @param {string} avatarImageUrl avatar image url
 * @param {string} beratungsLink beratungs link
 * @param {object} texts translation texts
 * @returns {string} html content
 */
function getInnerHtmlContent(avatarImageUrl, beratungsLink, texts) {
  const div = document.createElement("div");
  div.className = "wishlist--container";
  div.innerHTML = `
  <div class="beratungshero--banner">
    <div class="beratungshero--avatar-container">
      <img alt="" src="${avatarImageUrl}" />
      <div class="beratungshero--avatar-live">${texts.widgetLive}</div>
    </div>
    <div class="beratungshero--body">
      <div class="beratungshero--left">
        <div class="beratungshero--text">
          <div class="beratungshero--title">
            ${texts.wishlistTitle}
          </div>
          <div class="beratungshero-list">
            <div class="beratungshero--list-item">
              ${notSeen} ${texts.widgetBodyBullet1}
            </div>
          </div>
        </div>
      </div>
      <div class="beratungshero--buttons beratungshero--buttons-center">
        <a id="beratungshero-banner-video" class="beratungshero--more" href="${beratungsLink}" target="_blank" rel="noopener noreferrer">
          <span>${texts.wishlistButton}</span>
        </a>
      </div>
    </div>
  </div>
  `;

  return div;
}

/**
 * Create wishlist banner element to inject into the dom.
 * @param {object} texts translation texts
 * @param {string} tenant tenant name
 * @param {string} lang language
 * @param {string} avatarImage consultant avatar image url
 * @param {string} consultantId consultant id for url
 * @returns {HTMLElement}
 */
export default function createWishlistBannerElement(
  texts,
  tenant,
  lang,
  avatarImageUrl,
  consultantId
) {
  if (!tenant) throw new Error("tenant is required");
  if (!texts) throw new Error("texts is required");
  if (!texts.widgetLive) throw new Error("texts.widgetLive is required");
  if (!texts.wishlistTitle) throw new Error("texts.wishlistTitle is required");
  if (!texts.widgetBodyBullet1)
    throw new Error("texts.widgetBodyBullet1 is required");
  if (!texts.wishlistButton)
    throw new Error("texts.wishlistButton is required");
  if (!lang) throw new Error("lang is required");
  if (!avatarImageUrl) throw new Error("avatarImageUrl is required");

  const urlParams = new URLSearchParams();
  urlParams.append("connect", "auto");
  urlParams.append(
    "url",
    encodeURIComponent(document.location.href.split("#")[0])
  );
  urlParams.append("consultantId", consultantId);

  const finalLang = lang === "en" ? "de" : lang;
  const beratungsLink = `${
    window.BH_URL
  }/${tenant}/${finalLang}?${urlParams.toString()}`;

  const wishlistBanner = document.createElement("div");
  wishlistBanner.appendChild(
    getInnerHtmlContent(avatarImageUrl, beratungsLink, texts)
  );

  return wishlistBanner;
}
