import { getElement } from "../utilities";
import { logEvent, loadTexts } from "../widget";

const ELEMENT_CLASS = "beratungshero--miocolor-product-finder";

export async function createMiocolorProductFinderElement({
  url,
  consultant,
  theme,
  lang
}) {
  const texts = loadTexts(lang);
  const a = document.createElement("a");
  a.className = ELEMENT_CLASS;
  a.target = "_blank";
  a.rel = "noopener noreferrer";
  a.href = `${window.BH_URL}/${theme}/${lang}?url=${encodeURIComponent(
    url
  )}&consultantId=${consultant._id}&connect=auto`;
  a.innerHTML = `
  <span>${texts.stickerButton[theme] || texts.stickerButton.default}</span>
  `;

  const clearDiv = document.createElement("div");
  clearDiv.style = "clear: both;";

  const anchorElement = await getElement("#smartassistant-advisor");

  if (anchorElement) {
    insertElement(anchorElement, a);
    insertElement(anchorElement, clearDiv);
    addLogging();
  }
}

function insertElement(anchorElement, elementToInsert) {
  anchorElement.parentNode.insertBefore(elementToInsert, anchorElement);
}

function addLogging() {
  logEvent("MIOCOLOR_PRODUCT_FINDER_SHOWN");

  const element = document.querySelector(`.${ELEMENT_CLASS}`);
  if (element) {
    element.onclick = function () {
      logEvent("MIOCOLOR_PRODUCT_FINDER_CLICKED");
    };
  }
}

export function isMiocolorProductFinderPage(url) {
  return url.match(/\/cp\/produktfinder-malen/);
}
