import { getElements, sendHotjarEvent } from "./utilities";
import { logEvent, loadTexts } from "./widget";

export async function createSticker({ url, consultant, theme, lang }) {
  if (window?.BH?.stickerIsDisabled) return;

  const texts = loadTexts(lang);
  const a = window?.BH?.newSticker
    ? createNewSticker(texts)
    : createOldSticker(texts, theme);

  const urlParams = new URLSearchParams();

  urlParams.append("url", encodeURIComponent(url));
  urlParams.append("consultantId", consultant._id);
  urlParams.append("source", "sticker");

  const finalLang = lang === "en" ? "de" : lang;
  a.href = `${window.BH_URL}/${theme}/${finalLang}?${urlParams.toString()}`;

  const labelBoxes = await getElements(".lNrTChs5SapUD1YBIbMU");

  if (labelBoxes) {
    const labelBox = labelBoxes.length > 1 ? labelBoxes[1] : labelBoxes[0];
    insertSticker(labelBox, a);
  }
}

function createNewSticker() {
  const a = document.createElement("a");
  a.className = "js__beratungshero--sticker beratungshero--sticker--new";
  a.target = "_blank";
  a.rel = "noopener noreferrer";

  a.innerHTML = `
  <img class="beratungshero--sticker--new__consultant-image" src="https://res.cloudinary.com/db4fij86r/image/upload/v1631613733/consultants/MicrosoftTeams-image_21_n1mw8k.jpg" alt="consultant image" />
  <img class="beratungshero--sticker--new__consultant-image beratungshero--sticker--new__consultant-image--right" src="https://res.cloudinary.com/db4fij86r/image/upload/v1613570250/consultants/zwnk5rtfbqyjnxqyb7tg.jpg" alt="consultant image" />
  <span>Video-Beratung zum Produkt starten</span>
  <div class="beratungshero--sticker--new__circle">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 0L6.59 1.41L12.17 7H0V9H12.17L6.59 14.59L8 16L16 8L8 0Z" fill="white"/>
    </svg>
  </div>
  `;
  return a;
}

function createOldSticker(texts, theme) {
  const a = document.createElement("a");
  a.className = "js__beratungshero--sticker beratungshero--sticker";
  a.target = "_blank";
  a.rel = "noopener noreferrer";

  a.innerHTML = `
  <span>${texts.stickerButton[theme] || texts.stickerButton.default}</span>
  <span class="beratungshero--sticker-live">${texts.widgetLive}</span>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path></svg>
  `;
  return a;
}

function insertSticker(labelBox, a) {
  labelBox.parentNode.insertBefore(a, labelBox);
  logEvent("STICKER_SHOWN");

  const stickerLink = document.querySelector(".js__beratungshero--sticker");
  if (stickerLink) {
    stickerLink.onclick = function () {
      logEvent("BUTTON_STICKER_CLICKED");
      sendHotjarEvent("beratungshero_button_sticker_clicked");
    };
  }

  logOptimizeEventStickerShown();
}

function logOptimizeEventStickerShown() {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ event: "optimize.macaron.shown" });
}
