{
  "bannerTitle": "<b>Ha altre domande?</b><br />Le possiamo offrire una consulenza competente tramite una conversazione video.",
  "bannerButton": "Comincia la videoconsulenza",
  "cartButton": "Collegare adesso",
  "cartTitle": "<b>Hai domande sulla tua ordinazione?</b><br /><b>Possiamo offrirti una consulenza professionale con una videochiamata.</b>",
  "configuratorStartPageTitle": "Aiuto nella configurazione",
  "configuratorStartPageSubtitle": "Per la configurazione utilizza la nostra videoconsulenza, comodamente da casa tua.",
  "configuratorStartPageButton": "Avvia la videoconsulenza",
  "configuratorStartPageText": "Hai bisogno di aiuto con la configurazione? Utilizza la nostra videoconsulenza: live, gratuita e senza impegno. ​",
  "configuratorStartPageTextOffline": "Hai bisogno di aiuto con la configurazione? Utilizza la nostra videoconsulenza: live, gratuita e senza impegno. ​",
  "configuratorStartPageLink": "Inizia subito la videoconsulenza",
  "configuratorStartPageLinkOffline": "Fissa ora un appuntamento",
  "configuratorConsultant": "Consulenza",
  "configuratorButton": "Hai bisogno di aiuto? La nostra videoconsulenza è qui per te",
  "mobileConfiguratorButton": "Al configuratore di mobili tramite videoconsulenza",
  "nivekiTitle": "Come possiamo aiutarti?",
  "nivekiButton1": "Ho domande sulla mia ordinazione",
  "nivekiButton1Link": {
    "doitgarden": "https://www.doitgarden.ch/it/cp/contactFormPage",
    "melectronics": "https://www.melectronics.ch/it/cp/contactFormPage",
    "micasa": "https://www.micasa.ch/it/cp/contactFormPage",
    "sportxx": "https://www.sportx.ch/it/cp/contactFormPage"
  },
  "nivekiButton1Subtext": "Problemi con fattura, consegna, riparazione...",
  "nivekiButton2": "Ho domande sullo shop online",
  "nivekiButton2Link": {
    "doitgarden": "https://help.doitgarden.ch/hc/it/categories/360002637600-Prima-del-mio-ordine",
    "melectronics": "https://help.melectronics.ch/hc/it/categories/360002647099-Prima-del-mio-ordine",
    "micasa": "https://help.micasa.ch/hc/it/categories/360002637760-Prima-del-mio-ordine",
    "sportxx": "https://help.sportx.ch/hc/it/categories/360002647659-Prima-del-mio-ordine"
  },
  "nivekiButton2Subtext": "Problemi con buoni, pagamento...",
  "nivekiButton3": "Ho domande sulla mia filiale",
  "nivekiButton3Link": {
    "doitgarden": "https://www.doitgarden.ch/it/cp/storefinder",
    "melectronics": "https://www.melectronics.ch/it/cp/storefinder",
    "micasa": "https://www.micasa.ch/it/cp/storefinder",
    "sportxx": "https://www.sportx.ch/it/cp/storefinder"
  },
  "nivekiButton3Subtext": "Tutto su orari di apertura, stock filiale...",
  "nivekiPrimaryButton": "Avanti a consulenza prodotti",
  "stickerButton": {
    "default": "Videoconsulenza sul prodotto"
  },
  "themeSpecific": {
    "bikeworld": {
      "sizeTableButton": "Determinare la dimensione del telaio tramite consultazione video"
    }
  },
  "widgetAvatarTitle": {
    "default": "Videoconsulenza"
  },
  "widgetAvatarTitleBooking": "Prenota la<br />Videoconsulenza",
  "widgetTitle": {
    "klubschule": "Incertezze? Nessun problema, siamo qui per voi.",
    "default": "Da casa la stessa esperienza d’acquisto come in negozio!"
  },
  "widgetTitleBooking": "Pianificare una consulenza video in diretta gratuita.",
  "widgetBodyBullet1": "La sua immagine non verrà trasmessa",
  "widgetBodyBooking": {
    "klubschule": "Incertezze? Nessun problema, siamo qui per voi.",
    "default": "Shopping da casa con la stessa esperienza di acquisto in negozio!"
  },
  "widgetButton": {
    "default": "Comincia la Videoconsulenza"
  },
  "widgetButtonBooking": "Fissare un appuntamento",
  "widgetHours": "__FROM__ alle __TO__",
  "widgetInfoLink": "Funziona cosi",
  "widgetLive": "LIVE",
  "wishlistButton": "Mostrare i prodotti",
  "widgetSundayToo": "Anche la domenica!",
  "wishlistTitle": "<b>Desidera la nostra assistenza nella scelta del prodotto?</b><br /> Tutti i prodotti possono essere mostrati LIVE tramite una conversazione video.",
  "mBudget": {
    "widget": {
      "title": "Con l'abbonamento per cellulare fino a 150.- di riduzione sul nuovo smartphone!",
      "subtitle": "Fatti consigliare tramite video. La sua immagine non verrà trasmessa",
      "cta": "Avvia una videoconsulenza e approfittane"
    },
    "banner": {
      "title": "<b>Con l'abbonamento per cellulare fino a 150.- di riduzione sul nuovo smartphone!</b><br />Possiamo offrirti una consulenza professionale con una videochiamata.",
      "cta": "Avvia una videoconsulenza e approfittane"
    }
  }
}
