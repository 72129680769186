function getColors(theme) {
  switch (theme) {
    case "bikeworld":
      return {
        primary: "#e6000a",
        primaryDark: "#b30008",
        avatarAnimation: "#1d1d1b",
        popup: "#333",
        popupBg: "#f2f2f2",
        popupBgDark: "#b30008",
        popupBorder: "1px solid #1d1d1b",
        popupButton: "#fff",
        popupButtonBg: "#e6000a",
        popupClose: "#fff",
        popupCloseBg: "#e6000a",
        stickerBg: "#1d1d1b",
        stickerBgHover: "#1d1d1b",
        widget: "#fff",
        widgetBg: "#1d1d1b",
        widgetBorder: "#1d1d1b",
        nivekiButtonBg: "#1d1d1b"
      };
    case "doitgarden":
      return {
        primary: "#063",
        primaryDark: "#00331a",
        avatarAnimation: "#063",
        popup: "#333",
        popupBg: "#eee",
        popupBgDark: "#00331a",
        popupBorder: "2px solid #063",
        popupButton: "#fff",
        popupButtonBg: "#063",
        popupClose: "#fff",
        popupCloseBg: "#f60",
        nivekiButtonBg: "#00994d"
      };
    case "miocolor":
      return {
        primary: "#f60",
        primaryDark: "#00331a",
        avatarAnimation: "#f60",
        popup: "#333",
        popupBg: "#eee",
        popupBgDark: "#00331a",
        popupBorder: "2px solid #063",
        popupButton: "#fff",
        popupButtonBg: "#063",
        popupClose: "#fff",
        popupCloseBg: "#f60",
        nivekiButtonBg: "#00994d",
        liveBg: "#063"
      };
    case "melectronics":
      return {
        primary: "#015ba7",
        primaryDark: "#163d82",
        avatarAnimation: "#015ba7",
        popup: "#fff",
        popupBg: "#015ba7",
        popupBgDark: "#163d82",
        popupBorder: "1px solid #015ba7",
        popupButton: "#fff",
        popupButtonBg: "#f60",
        popupClose: "#fff",
        popupCloseBg: "#f60",
        nivekiButtonBg: "#0179da"
      };
    case "sportxx":
      return {
        primary: "#640015",
        primaryDark: "#500011",
        avatarAnimation: "#333333",
        popup: "#333",
        popupBg: "#f2f2f2",
        popupBgDark: "#1F1F1F",
        popupBorder: "1px solid #333333",
        popupButton: "#fff",
        popupButtonBg: "#640015",
        popupClose: "#fff",
        popupCloseBg: "#640015",
        stickerBg: "#333333",
        stickerBgHover: "#333333",
        widget: "#fff",
        widgetBg: "#333333",
        widgetBorder: "#333333",
        nivekiButtonBg: "#333333"
      };
    case "sportx":
      return {
        primary: "#630015", // Using sportx.primary
        primaryDark: "#191919", // Using sportx.primaryDark
        avatarAnimation: "#f5a29d", // Using sportx.primaryLight, this might not be the perfect match
        popup: "#333333", // Using sportx.secondaryLight
        popupBg: "#F4F4F4", // Using sportx.primaryLight
        popupBgDark: "#1F1F1F", // Unchanged as no equivalent color was provided
        popupBorder: "1px solid #F4F4F4", // Using sportx.primaryLight, this might not be the perfect match
        popupButton: "#fff", // Unchanged as no equivalent color was provided
        popupButtonBg: "#640015", // Using sportx.secondary
        popupClose: "#fff", // Unchanged as no equivalent color was provided
        popupCloseBg: "#640015", // Using sportx.secondary
        stickerBg: "#333333", // Using sportx.secondaryLight
        stickerBgHover: "#333333", // Using sportx.secondaryLight
        widget: "#fff", // Unchanged as no equivalent color was provided
        widgetBg: "#333333", // Using sportx.secondaryLight
        widgetBorder: "#333333", // Using sportx.secondaryLight
        nivekiButtonBg: "#333333" // Using sportx.secondaryLight
      };
    case "klubschule":
      return {
        primary: "#0053A6",
        primaryDark: "#00458d",
        avatarAnimation: "#0053A6",
        popup: "#333",
        popupBg: "#f2f2f2",
        popupBgDark: "#00458d",
        popupBorder: "1px solid #0053A6",
        popupButton: "#fff",
        popupButtonBg: "#0053A6",
        popupClose: "#fff",
        popupCloseBg: "#0053A6",
        stickerBg: "#1d1d1b",
        stickerBgHover: "#1d1d1b",
        widget: "#fff",
        widgetBg: "#0053A6",
        widgetBorder: "#0053A6"
      };
    default:
      // micasa
      return {
        primary: "#167f77",
        primaryDark: "#0e544e",
        avatarAnimation: "#167f77",
        popup: "#fff",
        popupBg: "#167f77",
        popupBgDark: "#0e544e",
        popupBorder: "1px solid #167f77",
        popupButton: "#fff",
        popupButtonBg: "#fcb23b",
        popupClose: "#167f77",
        popupCloseBg: "#fff",
        nivekiButtonBg: "#1ea99d"
      };
  }
}

export function createStyles(theme, lang) {
  const {
    avatarAnimation,
    primary,
    primaryDark,
    popup,
    popupBg,
    popupBorder,
    popupBgDark,
    popupButton,
    popupButtonBg,
    popupClose,
    popupCloseBg,
    popupSolidBg,
    stickerBg,
    stickerBgHover,
    widget,
    widgetBg,
    widgetBorder,
    nivekiButtonBg,
    liveBg,
    liveBgPopup,
    fontFamily = "inherit"
  } = getColors(theme);

  const popupCss = `
    .beratungshero--root {
      box-sizing: border-box;
      color: ${popup};
      font-family: ${fontFamily}
    }

    .beratungshero--root * {
      box-sizing: border-box;
    }

    .beratungshero--button-reset {
      border: none;
      cursor: pointer;
      margin: 0;
      padding: 0;
      width: auto;
      overflow: visible;
      background: transparent;
      color: inherit;
      font: inherit;
      line-height: normal;
      -webkit-font-smoothing: inherit;
      -moz-osx-font-smoothing: inherit;
      -webkit-appearance: none;
    }

    .beratungshero--button-reset:focus {
      outline: none;
    }

    .beratungshero--close {
      align-items: center;
      background-color: ${popupCloseBg};
      border-radius: 50%;
      border: 5px solid ${popupSolidBg || popupBg};
      color: ${popupClose};
      cursor: pointer;
      display: flex;
      height: 30px;
      justify-content: center;
      position: absolute;
      margin-top: -5px;
      margin-right: -5px;
      top: 0;
      right: 0;
      width: 30px;
    }

    .beratungshero--close--mobile {
      align-items: center;
      background-color: ${popupCloseBg};
      border-radius: 50%;
      margin: 5px;
      color: ${popupClose};
      cursor: pointer;
      display: flex;
      height: 24px;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0;
      width: 24px;
      z-index: 1;
    }

    .beratungshero--close svg {
      fill: currentColor;
    }

    .beratungshero--close--mobile svg {
      fill: currentColor;
    }

    .beratungshero--skew {
      // -moz-transform: skew(20deg, 0deg);
      // -webkit-transform: skew(20deg, 0deg);
      // -o-transform: skew(20deg, 0deg);
      // -ms-transform: skew(20deg, 0deg);
      // transform: skew(20deg, 0deg);
      border-bottom: 110px solid red;
      border-right: 25px solid transparent;
      height: 0;
    }

    .beratungshero--avatar {
      position: fixed;
      bottom: 10px;
      right: 10px;
      z-index: 100;
    }

    .beratungshero--avatar.beratungshero--avatar--multiple1 {
      right: 50px;
    }

    .beratungshero--avatar.beratungshero--avatar--multiple2 {
      right: 21px;
    }

    .beratungshero--avatar.beratungshero--avatar--left {
      left: 10px;
      right: initial;
    }

    .beratungshero--avatar img {
      border-radius: 50%;
      border: 2px solid ${widgetBorder || primary};
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }

    .beratungshero--avatar video {
      border-radius: 50%;
      border: 2px solid #000;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }

    .beratungshero--avatar-img {
      position: relative;
      height: 80px;
      width: 80px;
      margin-left: auto;
      margin-right: auto;
    }

    .beratungshero--avatar-img:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      background-color: ${avatarAnimation};
      border-radius: 50%;
      will-change: transform;
    }

    .beratungshero--avatar-img.beratungshero--avatar-pulse:after {
      animation: beratungshero-pulse 2s infinite;
    }

    @keyframes beratungshero-pulse {
      0% {
        transform: scale(1);
        opacity: 1;
      }
      100% {
        transform: scale(1.2);
        opacity: 0;
      }
    }

    .beratungshero--avatar-img--sm {
      height: 60px;
      width: 60px;
    }

    .beratungshero--avatar-img--xs {
      height: 40px;
      width: 40px;
    }

    .beratungshero--avatar-img--multiple1-left {
      position: absolute;
      margin-left: -40px;
      margin-top: auto;
      margin-bottom: auto;
      top: 0;
      bottom: 0;
      left: 0;
    }

    .beratungshero--avatar-img--multiple1-right {
      position: absolute;
      margin-right: -40px;
      margin-top: auto;
      margin-bottom: auto;
      top: 0;
      bottom: 0;
      right: 0;
    }

    .beratungshero--avatar--multiple2 .beratungshero--avatar-img {
      margin-left: -18px;
    }

    .beratungshero--avatar-img--multiple2-right {
      position: absolute;
      margin-right: -16px;
      margin-top: 2px;
      right: 0;
    }

    .beratungshero--avatar-img--multiple2-top-right {
      position: absolute;
      margin-right: -3px;
      margin-top: -55px;
      top: 0;
      right: 0;
    }

    .beratungshero--avatar-title {
      background-color: ${widgetBg || primary};
      border-radius: 4px;
      color: ${widget || "#fff"};
      font-size: 12px;
      line-height: 1;
      padding: 2px 4px;
    }

    .beratungshero--avatar-additional-title {
      background-color: ${widgetBg || primary};
      border-radius: 4px;
      color: ${widget || "#fff"};
      font-size: 12px;
      line-height: 1;
      padding: 2px 4px;
      width: 65px;
      margin: 0 auto;
    }

    .beratungshero--popup {
      background: ${popupBg};
      border: ${popupBorder};
      border-radius: 4px;
      padding: 20px;
      position: fixed;
      bottom: 10px;
      left: 10px;
      right: 10px;
      transition: all .5s ease-in-out;
      transform: scale(0);
      transform-origin: right bottom;
      z-index: 101;
    }

    .beratungshero--popup--mobile {
      padding: 0px;
      border: none;
      bottom: 5px;
      left: 5px;
      right: 5px;
    }

    .beratungshero--popup.beratungshero--popup--left {
      transform-origin: left bottom;
      left: 10px;
    }

    .beratungshero--popup--mobile.beratungshero--popup--left {
      left: 5px;
    }

    .beratungshero--title {
      margin: 0 15px 24px 15px;
      text-align: center;
      font-weight: 700;
    }

    .beratungshero--title--mobile {
      margin-bottom: 9px;
      text-align: left;
      font-weight: 700;
    }

    .beratungshero--body {
      position: relative;
    }

    .beratungshero--links-container {
      display: flex;
      justify-content: space-between;
    }

    .beratungshero--links-container--mobile {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      background-color: ${popupBgDark};
      border: 1px solid ${popupBgDark};
      border-radius: 0 0 4px 4px;
      font-size: 12px;
    }

    .beratungshero--link a {
      display: flex;
      align-items: center;
      border-bottom: 0 !important;
      color: ${popup || "#fff"} !important;
      text-decoration: underline !important;
    }

    .beratungshero--link--mobile span {
      display: flex;
      align-items: center;
      color: #fff !important;
    }

    .beratungshero--link--mobile a {
      display: flex;
      align-items: center;
      border-bottom: 0 !important;
      color: #fff !important;
      text-decoration: underline !important;
    }

    .beratungshero--avatar-container {
      position: relative;
      line-height: 0;
    }

    .beratungshero--avatar-live {
      background-color: ${liveBg || "#e84a42"};
      border-radius: 2px;
      color: #fff;
      font-size: 8px;
      padding: 4px;
      position: absolute;
      bottom: 10px;
      left: calc(50% + 20px);
      line-height: 1;
    }

    .beratungshero--avatar .beratungshero--avatar-live {
      bottom: 0;
      right: 0;
      left: auto;
    }

    .beratungshero--body img {
      border-radius: 50%;
      display: block;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      width: 100px;
    }

    .beratungshero-list {
      margin-top: 20px;
      display: flex;
      justify-content: center;
    }

    .beratungshero--list-item {
      align-items: center;
      display: flex;
      margin-bottom: 4px;
    }

    .beratungshero--list-item.beratungshero--list-item--pdp {
      font-size: 13px;
    }

    .beratungshero--icon {
      flex-shrink: 0;
      fill: currentColor;
      margin-right: 10px;
      width: 20px;
    }

    .beratungshero--icon-calendar {
      fill: currentColor;
      margin-right: 5px;
    }

    .beratungshero--icon-info {
      flex-shrink: 0;
      fill: currentColor;
      margin-right: 5px;
      width: 20px;
    }

    .beratungshero-niveki-button-wrapper {
      display: flex;
      justify-content: center;
    }

    .beratungshero-niveki-button {
      background-color: ${nivekiButtonBg};
      border-radius: 4px;
      border: 0 !important;
      color: ${popupButton} !important;
      margin-bottom:16px;
      overflow: hidden;
      width: 100%;
      padding: 10px;
      text-align: left;
      text-decoration: none;
    }

    .beratungshero-new-niveki-button {
      border-radius: 4px;
      border: 2px solid ${popupButton} !important;
      color: ${popupButton} !important;
      margin-bottom:16px;
      overflow: hidden;
      width: 100%;
      padding: 10px;
      text-align: left;
      text-decoration: none;
      display: flex;
      font-weight: bold;
      justify-content: center;
      margin: 20px auto;
    }

    .beratungshero-content--mobile {
      padding: 20px;
      border: ${popupBorder};
      border-radius: 4px;
    }

    .beratungshero-niveki-button > span {
      font-weight: bold;
    }

    .beratungshero-niveki-button > div {
      font-size: 12px;
    }

    .beratungshero--more {
      align-items: center;
      background: ${popupButtonBg};
      border-radius: 4px;
      border: 0 !important;
      color: ${popupButton} !important;
      display: flex;
      font-weight: bold;
      justify-content: center;
      margin: 20px auto;
      overflow: hidden;
      padding: 10px;
      text-align: center;
      text-decoration: none !important;
    }

    .beratungshero--more--mobile {
      align-items: center;
      background-color: ${popupButtonBg};
      border-radius: 4px;
      border: 0 !important;
      color: ${popupButton} !important;
      display: flex;
      font-weight: bold;
      justify-content: center;
      margin: 0 auto;
      overflow: hidden;
      padding: 10px;
      text-align: center;
      text-decoration: none;
    }

    .beratungshero--chat {
      align-items: center;
      border: 0 !important;
      color: #fff !important;
      display: flex;
      font-weight: bold;
      justify-content: center;
      margin-top: 10px;
      margin-left: auto;
      margin-right: auto;
      overflow: hidden;
      padding: 10px;
      text-align: center;
      text-decoration: none;
    }

    .beratungshero--hidden {
      display: none !important;
    }

    .beratungshero--header {
      background-size: cover;
      background-position: top;
      height: 200px;
      margin-bottom: 20px;
      position: relative;
      border-radius: 4px;
    }

    .beratungshero--header-live {
      position: absolute;
      top: 10px;
      left: 10px;
      background-color: ${liveBgPopup || "#e84a42"};
      color: #fff;
      padding: 6px;
      border-radius: 4px;
      line-height: 1;
    }

    .beratungshero--show-video {
      margin-right: 10px;
    }

    .beratungshero--popup--video {
      width: 180px;
      left: auto;
      padding: 11px 11px 9px 11px;
      font-size: 12px;
    }

    .beratungshero-popup__video {
      position: relative;
      margin-bottom: 9px;
    }

    .beratungshero-popup__video__live {
      font-size: 11px;
      top: 6px;
      left: 6px;
    }

    .beratungshero-popup__video__close {
      width: 36px;
      height: 36px;
      border-width: 6px;
    }

    .beratungshero-popup__video > video {
      width: 100%;
      height: auto;
    }

    .beratungshero-popup__video__button-no-sound {
      position: absolute;
      width: 76px;
      height: 76px;
      border-radius: 38px;
      background: rgba(255, 255, 255, 0.33);
      border: 1px solid rgba(0, 0, 0, 0.15);
      top: 50%;
      margin-top: -34px;
      left: 50%;
      margin-left: -34px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .beratungshero-popup__video__button-wrapper {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 8px 8px 12px 8px;
    }

    .beratungshero-popup__video__button {
      width: 100% !important;
      padding: 6px;
      margin: 0;
      font-size: 12px;
    }

    @media screen and (min-width: 480px) {
      .beratungshero--popup {
        left: auto;
        width: 440px;
      }

      .beratungshero--popup--video {
        width: 214px;
        font-size: inherit;
        padding: 13px 13px 9px 13px;
      }

      .beratungshero-popup__video__button {
        font-size: 14px;
      }

      .beratungshero--more, .beratungshero-new-niveki-button, .beratungshero--chat {
        width: 75%;
      }

      .beratungshero-niveki-button {
        width: 85%;
      }
    }

    ${
      (theme === "sportxx" || theme === "sportx") && lang === "de"
        ? ""
        : `.back-to-top:not(.back-to-top__hidden) {
          bottom: 120px;
        }`
    }

    @media screen and (min-width: 768px) {
      .beratungshero--avatar {
        bottom: 30px;
        right: 30px;
      }

      .beratungshero--avatar.beratungshero--avatar--left {
        left: 30px;
      }

      .beratungshero--avatar.beratungshero--avatar--multiple1 {
        right: 70px;
      }

      .beratungshero--avatar.beratungshero--avatar--multiple2 {
        right: 46px;
      }

      .beratungshero--popup {
        bottom: 30px;
        right: 30px;
      }

      .beratungshero--popup.beratungshero--popup--left {
        left: 30px;
      }

      ${
        (theme === "sportxx" || theme === "sportx") && lang === "de"
          ? ""
          : `.back-to-top:not(.back-to-top__hidden) {
          bottom: 140px;
        }`
      }
    }
  `;

  const bannerCss = `
  .beratungshero--root {
    width: 100%;
  }

  .beratungshero--banner {
    margin-top: 48px;
    width: 100%;
  }

  .beratungshero--banner .beratungshero--title {
    margin: 0 auto 0 auto;
    text-align: left;
  }

  .beratungshero--banner .beratungshero--body {
    background-color: #f2f2f2;
    color: #333;
    padding: 20px;
  }

  .beratungshero--banner .beratungshero--body.beratungshero--body--pdp {
    background-color: transparent;
  }

  .beratungshero--left {
    flex: 1;
  }

  .beratungshero--banner img {
    height: 120px;
    margin-left: 20px;
  }

  .beratungshero--banner .beratungshero--chat {
    color: #333 !important;
  }

  .beratungshero--banner .beratungshero--avatar-live {
    bottom: auto;
    top: 0;
    left: 140px;
    font-size: 12px;
  }

  .beratungshero--banner .beratungshero--list-item svg {
    fill: #555;
  }

  .beratungshero--banner .beratungshero-list {
    justify-content: flex-start;
  }

  .beratungshero--banner .beratungshero--buttons {
    display: flex;
    margin-top: 20px;
  }

  .beratungshero--banner .beratungshero--buttons.beratungshero--buttons--pdp {
    margin-top: 5px;
  }

  .beratungshero--banner .beratungshero--buttons.beratungshero--buttons--pdp .beratungshero--more {
    padding: 10px 10px;
  }

  .beratungshero--banner .beratungshero--buttons-center {
    align-items: center;
  }

  .beratungshero--banner .beratungshero--more {
    flex-direction: column;
    line-height: 1;
    margin-top: 0;
    padding: 10px 50px;
  }

  .beratungshero--banner .beratungshero--buttons-center .beratungshero--more {
    width: 100%;
  }

  .beratungshero--banner .beratungshero--buttons .beratungshero--icon {
    margin-bottom: 10px;
    margin-right: 0;
  }

  .wishlist--container .beratungshero--banner {
    margin-bottom: 48px;
  }

  .p-cart--container .beratungshero--banner {
    margin-bottom: 48px;
  }

  @media screen and (min-width: 480px) {
    .beratungshero--banner .beratungshero--more {
      max-width: 360px;
      width: auto;
    }

    .beratungshero--banner .beratungshero--buttons-center .beratungshero--more {
      max-width: 100%;
      width: 100%;
    }

    .beratungshero--banner .beratungshero--chat {
      max-width: 360px;
      width: auto;
    }

    .wishlist .beratungshero--banner .beratungshero--body {
      display: flex;
    }

    .wishlist--container .beratungshero--banner .beratungshero--buttons-center {
      padding-left: 10px;
    }

    .p-cart .beratungshero--banner .beratungshero--body {
      display: flex;
    }

    .p-cart--container .beratungshero--banner .beratungshero--buttons-center {
      padding-left: 10px;
    }
  }

  @media screen and (min-width: 992px) {
    .beratungshero--banner .beratungshero--body:not(.beratungshero--body--pdp) {
      display: flex;
    }

    .beratungshero--banner.beratungshero--banner--pdp {
      display: flex;
    }

    .beratungshero--body.beratungshero--body--pdp {
      padding-top: 0px;
      margin-left: 45px;
    }

    .beratungshero--banner .beratungshero--text {
      padding-right: 20px;
    }

    .beratungshero--banner .beratungshero--buttons {
      display: block;
      margin-top: 0;
    }

    .beratungshero--banner .beratungshero--buttons.beratungshero--buttons-center {
      display: flex;
    }

    .beratungshero--banner .beratungshero--more-chat {
      margin-top: 20px;
    }
  }

  @media screen and (min-width: 1200px) {
  }

  @media screen and (min-width: 1560px) {
  }

  .beratungshero-banner-wrapper {
    position: relative;
    padding-top: 27px;
    margin-top: -28px;
    margin-right: -20px;
    margin-left: -20px;
  }

  .beratungshero-banner {
    margin: 27px;
    box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 16px;
    position: relative;
    background-color: white;
  }

  .beratungshero-banner-background {
    width: 100%;
    background: ${primary};
    position: absolute;
    height: 65%;
    border-radius: 16px;
  }

  .beratungshero-banner__img {
    width: 100%;
    height: 188px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 16px 16px 0px 0px;
  }

  .beratungshero-banner__content {
    padding: 10px 24px 24px 24px;
  }

  .beratungshero-banner__title {
    font-size: 20px;
    font-weight: 600;
  }

  .beratungshero-banner__list {
    margin: 16px 0px 27px 5px;
  }

  .beratungshero-banner__list__item {
    align-items: center;
    display: flex;
    margin-bottom: 12px;
    font-size: 14px;
  }

  .beratungshero-banner__list__item > svg {
    color: ${primary}
  }

  .beratungshero-banner__button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 13px;
    background: ${primary};
    border-radius: 8px;
    width: 100%;
    color: white !important;
  }

  .beratungshero-banner__button__live {
    display: flex;
    align-items: center;
    background: rgba(0, 0, 0, 0.4);
    padding: 4px 10px 4px 6px;
    font-size: 12px;
    border-radius: 4px;
    letter-spacing: 0.05em;
    margin-right: 10px;
  }

  .beratungshero-banner__button__live__green-bubble {
    width: 8px;
    height: 8px;
    background-color: #1FAF7C;
    border-radius: 4px;
    margin-right: 4px;
  }

  @media screen and (min-width: 992px) {
    .beratungshero-banner-wrapper {
      margin: 0;
    }

    .beratungshero-banner {
      display: flex;
      margin: 27px 0px 27px 27px;
    }

    .beratungshero-banner__img {
      width: 50%;
      height: auto;
      border-radius: 16px 0px 0px 16px;
    }

    .beratungshero-banner-background {
      width: 55%;
      height: 100%;
    }
  }

  `;

  const stickerCss = `
    .beratungshero--sticker {
      background-color: ${stickerBg || primary};
      color: white !important;
      position: absolute;
      right: 0;
      padding: 8px 16px;
      cursor: pointer;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      font-size: 90%;
      line-height: 1;
      z-index: 1;
      border-bottom: 0px !important;
    }

    @media screen and (max-width: 990px) {
      .beratungshero--sticker {
        top: 45px;
      }
    }

    .beratungshero--sticker:hover {
      background-color: ${stickerBgHover || primaryDark};
    }

    .beratungshero--sticker svg {
      fill: currentColor;
      height: 10px;
      margin-left: 8px;
      width: 10px;
    }

    .beratungshero--sticker-live {
      position: absolute;
      top: 0;
      right: 0;
      background-color: ${liveBg || "#e84a42"};
      padding: 4px;
      border-radius: 4px;
      margin-top: -16px;
      margin-right: 8px;
    }

    .beratungshero--sticker--new {
      background-color: ${stickerBg || primary};
      color: white !important;
      position: absolute;
      right: 0;
      padding: 4px 6px;
      cursor: pointer;
      border-radius: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      top: -5px;
      line-height: 1;
      z-index: 1;
      border-bottom: 0px !important;
    }

    .beratungshero--sticker--new > span {
      margin-left: 44px;
      max-width: 165px;
      line-height: 20px;
    }

    .beratungshero--sticker--new__consultant-image {
      border: 2px solid white;
      border-radius: 22px;
      height: 44px;
      width: 44px;
      flex-shrink: 0;
    }

    .beratungshero--sticker--new__consultant-image--right {
      position: absolute;
      left: 36px;
      z-index: -1;
    }

    .beratungshero--sticker--new__circle {
      background-color: ${primaryDark};
      color: white;
      border-radius: 22px;
      height: 44px;
      width: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 12px;
      flex-shrink: 0;
    }


    @media screen and (max-width: 990px) {
      .beratungshero--sticker--new {
        top: 29px;
      }
    }

    @media screen and (max-width: 425px) {
      .beratungshero--sticker--new {
        width: 100%;
      }
      .beratungshero--sticker--new > span {
        max-width: none;
      }
    }


    .beratungshero--mobile-configurator {
      border: 2px solid transparent;
      border-width: 2px !important;
      border-color: ${primary};
      color: ${primary};
      cursor: pointer;
      border-radius: 4px;
      text-decoration: none;
      line-height: 1;
      font-size: 1.4rem;
      padding: 6px 8px;
      width: 100%;
      font-weight: 700;
      font-style: normal;
      display: inline-flex;
      flex-flow: column nowrap;
      align-items: center;
    }

    .beratungshero--miocolor-product-finder {
      border: 2px solid transparent;
      border-width: 2px !important;
      border-color: ${primary};
      color: ${primary};
      cursor: pointer;
      border-radius: 4px;
      text-decoration: none;
      font-size: 1.4rem;
      font-weight: 700;
      font-style: normal;
      display: inline-flex;
      flex-flow: column nowrap;
      align-items: center;
      float: right;
      padding: 0 1em;
      height: 32px;
      line-height: 28px;
      margin-bottom: 10px;
    }

    @media screen and (max-width: 991px) {
      .beratungshero--miocolor-product-finder {
        margin-right: 8px;
      }
    }

    .beratungshero--miocolor-product-finder > span  {
      padding: 0 4px;
      font-family: "Helvetica Neue Condensed Bold",Helvetica,Arial,sans-serif;
      font-weight: 400;
    }

    .cursor-pointer {
      cursor: pointer;
    }

    .beratungshero-video-wrapper{
        overflow: hidden
    }

    .beratungshero-video-wrapper video{
        margin-left: -3px
    }

    .lazy {
      background-image: none !important;
    }

    .beratungshero-button {
      padding: 8px 8px;
      cursor: pointer;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 90%;
      line-height: 1;
      z-index: 1;
    }

    .beratungshero-button > span {
      text-align: center;
      font-weight: bold;
    }

    .beratungshero__livestream-fake__widget > video {
      height: 100%;
      width: 100%;
      border-radius: 9px;
      position: absolute;
    }

    .beratungshero__livestream-fake__live {
      z-index: 1;
      position: absolute;
      top: 7px;
      left: 7px;
      padding: 7px 8px;
      font-size: var(--live-label-font-size, 12px);
      color: var(--live-label-text-color, #ffffff);
      background-color: var(--live-label-bg-color, #E12147);
      border-radius: var(--border-radius, 0);
      font-weight: var(--live-label-font-weight, 'normal');
    }

    .beratungshero__livestream-fake__fader {
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: #000;
      opacity: 0;
      top: 0;
      left: 0;
      transition: opacity .2s;
      border-radius: 8px;
      cursor: pointer;
    }

    .beratungshero__livestream-fake--active > .beratungshero__livestream-fake__fader {
        opacity: .65;
    }

    .beratungshero__livestream-fake__cta {
      opacity: 0;
      z-index: 1;
      transition: opacity .2s;
      border: 1px solid #fff;
      text-align: center;
      padding: 8px;
      margin-left: 16px;
      margin-right: 16px;
      font-size: 12px;
      color: white;
    }

    .beratungshero__livestream-fake__cta-container {
      display: flex;
      align-items: center;
      height: 100%;
    }

    .beratungshero__livestream-fake__close {
      position: absolute;
      top: 0;
      right: 0;
      width: 44px;
      height: 44px;
      z-index: 1;
      transition: opacity .2s;
    }

    .beratungshero__livestream-fake--active .beratungshero__livestream-fake__cta {
        opacity: 1;
    }

    .beratungshero__livestream-fake__live-circle {
      position: fixed;
      bottom: 16px;
      right: 16px;
      width: 56px;
      height: 56px;
      text-transform: var(--live-label-text-transform, 'uppercase');
      color: var(--live-label-text-color, #ffffff);
      background-color: var(--live-label-bg-color, #E12147);
      font-weight: var(--live-label-font-weight, 'normal');
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      cursor: pointer;
      font-size: 12px;
      font-family: Haas Grot, Helvetica, Arial, Verdana, Tahoma, sans-serif;
    }

    .beratungshero__livestream-fake__overlay {
      position: fixed;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.55);
      overscroll-behavior: none;
      z-index: 2147483647;
      cursor: pointer;
    }

    .beratungshero__livestream-fake__overlay__content {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      width: 100%;
      max-width: 500px;
    }

    .beratungshero__livestream-fake__overlay__live {
      border-radius: 4px;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 500;
      background-color: #2a2a2a;
      font-family: "NeueHaasGrotText",sans-serif;
      padding: 8px;
      background-color: #e12147;
      color: #fff;
      position: absolute;
      top: 7px;
      left: 7px;
    }

    .beratungshero__livestream-fake__overlay__close {
      position: absolute;
      top: 0;
      right: 0;
      width: 48px;
      height: 48px;
      z-index: 1;
      transition: opacity .2s;
      cursor: pointer;
    }

    .beratungshero__livestream-fake__overlay__spinner {
      border: 5px solid #f3f3f3;
      -webkit-animation: spin 1s linear infinite;
      animation: spin 1s linear infinite;
      border-top: 5px solid #555;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      margin-right: auto;
      margin-left: auto;
    }

    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `;

  const switchCss =
    ".switch{display:inline-block;vertical-align:middle;cursor:pointer;font-family:Helvetica Neue,Helvetica,Arial,sans-serif;box-sizing:content-box;outline:none}.switch,.switch small{position:relative;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;-webkit-tap-highlight-color:transparent}.switch small{font-size:12px;top:0;border-radius:100%;text-align:center;display:block;background:#fff;box-shadow:0 1px 3px rgba(0,0,0,.4);-webkit-transition:all .2s;transition:all .2s;overflow:hidden;color:#000}.switch-default{width:50px;height:30px;border-radius:20px;line-height:30px}.switch-default small{width:30px;height:30px}.switch-small{border-radius:20px;height:20px;width:33px;line-height:20px}.switch-small small{height:20px;width:20px}.switch-large{border-radius:40px;height:40px;width:66px;line-height:40px}.switch-large small{height:40px;width:40px}.switch-disabled{opacity:.5}";

  const css = popupCss + bannerCss + stickerCss + switchCss;

  const style = document.createElement("style");
  style.type = "text/css";
  style.id = "bh-widget-style";
  style.appendChild(document.createTextNode(css));
  document.head.appendChild(style);
}

export function removeStyles() {
  console.log("removeStyles");
  const style = document.getElementById("bh-widget-style");
  console.log(style);
  if (style) {
    style.remove();
  }
}
