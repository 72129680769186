import { verifyInputs, createStickerElement, loadWidgetData } from "./helper";
import { loadTexts, logEvent } from "../widget";
import { shouldBeHiddenOnPage } from "../utilities";

/**
 * Renders a Beratungshero sticker.
 * @param {HTMLElement} anchor - The anchor element into which it will be rendered.
 * @param {object} config - The config object.
 * @param {string} config.lang - The current page language.
 * @param {("bikeworld"|"doitgarden"|"micasa"|"melectronics"|"sportx")} config.tenant - The tenant that to use. This will be used to determine the correct colors and consultant assets.
 * @returns {void}
 * @example
 * renderBanner(anchor, config);
 * @example
 * renderBanner(anchor, { lang: "de", tenant: "bikeworld" });
 */
export default async function renderSticker(anchor, config) {
  verifyInputs(anchor, config);

  try {
    const { lang, tenant } = config;
    if (shouldBeHiddenOnPage(tenant)) return;

    const {
      consultant,
      duringOfficeHours,
      consultantAvailable,
      tenant: tenantData
    } = await loadWidgetData(lang, tenant);

    const { touchpoints } = tenantData;
    if (
      touchpoints &&
      touchpoints.sticker &&
      touchpoints.sticker.enabled !== undefined &&
      !touchpoints.sticker.enabled
    )
      return;

    if (!duringOfficeHours || !consultantAvailable) return;
    if (!consultant || !consultant._id) throw new Error("No consultant found");

    const sticker = createStickerElement(loadTexts(lang), tenant);

    const urlParams = new URLSearchParams();
    urlParams.append(
      "url",
      encodeURIComponent(document.location.href.split("#")[0])
    );
    urlParams.append("consultantId", consultant._id);
    urlParams.append("source", "sticker");

    const finalLang = lang === "en" ? "de" : lang;
    sticker.href = `${
      window.BH_URL
    }/${tenant}/${finalLang}?${urlParams.toString()}`;

    anchor.appendChild(sticker);
    logEvent("STICKER_SHOWN");
  } catch (error) {
    console.error(error);
  }
}
