import { notSeen } from "../../icons";
import { isSmartphonePage } from "../../widget";

/**
 * Get the inner html content for the banner.
 * @param {string} avatarImageUrl avatar image url
 * @param {string} beratungsLink beratungs link
 * @param {object} texts translation texts
 * @returns {string} html content
 */
function getInnerHtmlContent(avatarImageUrl, beratungsLink, texts) {
  const isSmartphone = isSmartphonePage(document.location.href);

  return `
    <div class="beratungshero--banner beratungshero--banner--pdp">
      <div class="beratungshero--avatar-container">
        <img alt="" src="${avatarImageUrl}" />
        <div class="beratungshero--avatar-live">
          ${texts.widgetLive}
        </div>
      </div>
      <div class="beratungshero--body beratungshero--body--pdp">
        <div class="beratungshero--left">
          <div class="beratungshero--text">
            <div class="beratungshero--title">
              ${isSmartphone ? texts.mBudget.banner.title : texts.bannerTitle}
            </div>
            <div class="beratungshero-list">
              <div class="beratungshero--list-item beratungshero--list-item--pdp">
                ${notSeen} ${texts.widgetBodyBullet1}
              </div>
            </div>
          </div>
        </div>
        <div class="beratungshero--buttons beratungshero--buttons--pdp beratungshero--buttons-center">
          <a id="beratungshero-banner-video" class="beratungshero--more" href="${beratungsLink}" target="_blank" rel="noopener noreferrer">
            <span>
              ${isSmartphone ? texts.mBudget.banner.cta : texts.bannerButton}
            </span>
          </a>
        </div>
      </div>
    </div>`;
}

/**
 * Create a banner element to inject into the dom.
 * @param {object} texts translation texts
 * @param {string} tenant tenant name
 * @param {string} lang language
 * @param {string} avatarImage consultant avatar image url
 * @param {object} consultant consultant object
 * @returns {HTMLElement}
 */
export default function createBannerElement(
  texts,
  tenant,
  lang,
  avatarImageUrl,
  consultant
) {
  if (!tenant) throw new Error("tenant is required");
  if (!texts) throw new Error("texts is required");
  if (!texts.mBudget.banner.title)
    throw new Error("texts.mBudget.banner.title is required");
  if (!texts.mBudget.banner.cta)
    throw new Error("texts.mBudget.banner.cta is required");
  if (!texts.bannerTitle) throw new Error("texts.bannerTitle is required");
  if (!texts.bannerButton) throw new Error("texts.bannerButton is required");
  if (!texts.widgetLive) throw new Error("texts.widgetLive is required");
  if (!texts.widgetBodyBullet1)
    throw new Error("texts.widgetBodyBullet1 is required");
  if (!lang) throw new Error("lang is required");
  if (!avatarImageUrl) throw new Error("avatarImageUrl is required");
  if (!consultant) throw new Error("consultant is required");

  const urlParams = new URLSearchParams();
  urlParams.append("connect", "auto");
  urlParams.append(
    "url",
    encodeURIComponent(document.location.href.split("#")[0])
  );
  urlParams.append("consultantId", consultant._id);

  const finalLang = lang === "en" ? "de" : lang;
  const beratungsLink = `${
    window.BH_URL
  }/${tenant}/${finalLang}?${urlParams.toString()}`;

  const div = document.createElement("div");
  div.className =
    "beratungshero--root p-product-detail--item p-product-detail--item__details detail-navigation-section";
  div.innerHTML = getInnerHtmlContent(avatarImageUrl, beratungsLink, texts);

  return div;
}
