import { verifyInputs, loadWidgetData } from "./helper";
import { loadTexts, logEvent } from "../widget";
import { createWishlistBannerElement } from "./helper";
import { shouldBeHiddenOnPage } from "../utilities";

/**
 * Renders the whishlist Beratungshero banner.
 * @param {HTMLElement} anchor - The anchor element into which it will be rendered.
 * @param {object} config - The config object.
 * @param {string} config.lang - The current page language.
 * @param {("bikeworld"|"doitgarden"|"micasa"|"melectronics"|"sportx")} config.tenant - The tenant that to use. This will be used to determine the correct colors and consultant assets.
 * @returns {void}
 * @example
 * renderWishlistBanner(anchor, config);
 * @example
 * renderWishlistBanner(anchor, { lang: "de", tenant: "bikeworld" });
 */
export default async function renderWishlistBanner(anchor, config) {
  verifyInputs(anchor, config);

  try {
    const { lang, tenant } = config;
    if (shouldBeHiddenOnPage(tenant)) return;

    const { consultant, duringOfficeHours, consultantAvailable } =
      await loadWidgetData(lang, tenant);

    if (!duringOfficeHours || !consultantAvailable) return;
    if (!consultant || !consultant._id) throw new Error("No consultant found");

    let avatarImageUrl = consultant.imageAvatar;
    if (avatarImageUrl.indexOf("https://") === 0) {
      avatarImageUrl = avatarImageUrl.replace("upload/", "upload/w_240/");
    } else {
      avatarImageUrl = window.BH_URL + avatarImageUrl;
    }

    const banner = createWishlistBannerElement(
      loadTexts(lang),
      tenant,
      lang,
      avatarImageUrl,
      consultant._id
    );

    anchor.appendChild(banner);

    const moreLink = document.querySelector("#beratungshero-banner-video");
    moreLink.onclick = function () {
      logEvent("BUTTON_WISHLIST_CLICKED");
    };
  } catch (err) {
    console.error(err);
  }
}
